import { Group } from '@mantine/core';
import React from 'react';
import { FinancialClientInfo, InvoiceItem, PaymentQuoteItem } from '../../api/types';
import { Amount, invoiceAndQuote } from '../../domain';
import ServiceSumRow from './ServiceSumRow';
import { printAnyPrice } from '../../domain/invoiceAndQuote';
import { useIntl } from 'react-intl';
import currencyTranslations from './util/currencyTranslations';

type Props = {
	items: (InvoiceItem | PaymentQuoteItem)[];
	clientInfo: FinancialClientInfo | undefined;
	remove?: (indices: number | number[]) => void;
	exchangeRate?: Amount;
	hideConversion?: boolean;
};

export function ServiceSum({
	items,
	clientInfo,
	exchangeRate,
}: Props) {
	const intl = useIntl();
	const {
		original,
		convertedNoVat,
		convertedWithVat,
		vat,
	} = invoiceAndQuote.overallPrice(items, exchangeRate || Amount.parse('1'), clientInfo?.international || false);

	const currencies = currencyTranslations[intl.locale] || {};

	const currency = clientInfo?.currency ? currencies[clientInfo?.currency] || clientInfo.currency : '';
	const currencyBam = currencies.BAM || 'BAM';

	const rows = [];
	if (clientInfo?.currency !== 'BAM' && exchangeRate) {
		const overallOriginalLabel = intl.formatMessage({
			id: '0OgJhE',
			defaultMessage: 'Overall ({currency})',
			description: 'Label for overall price in original currency',
		}, { currency });
		rows.push(['0', overallOriginalLabel, printAnyPrice(original, intl.locale)]);
	}
	const targetCurrency = exchangeRate ? currencyBam : currency;
	const withoutVatLabel = intl.formatMessage({
		id: 'EGwqFj',
		defaultMessage: 'No VAT ({currency})',
		description: 'Label for overall price in converted currency, no VAT',
	}, { currency: targetCurrency });
	const vatLabel = intl.formatMessage({
		id: 'QiD59g',
		defaultMessage: 'VAT',
		description: 'Label for VAT amount',
	});
	const overallConvertedLabel = intl.formatMessage({
		id: 'AQNenk',
		defaultMessage: 'Overall ({currency})',
		description: 'Label for overall price in converted currency, with VAT',
	}, { currency: targetCurrency });
	rows.push(
		['1', withoutVatLabel, printAnyPrice(convertedNoVat, intl.locale)],
		['2', vatLabel, vat],
		['3', overallConvertedLabel, printAnyPrice(convertedWithVat, intl.locale)],
	);


	return (
		<Group direction="column" spacing={2}>
			{rows.map(([id, label, value]) => (
				<ServiceSumRow
					key={id}
					label={label}
				>
					{value}
				</ServiceSumRow>
			))}
		</Group>
	);
}
