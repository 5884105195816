import React from 'react';
import { FinancialClientService } from '../../api/types';
import { Table } from '@mantine/core';
import { printServiceUnitPrice } from '../../domain';

type Props = {
	data: FinancialClientService[];
	onClick: (client: FinancialClientService) => void;
}

export default function ClientServicesTable({ data, onClick }: Props): JSX.Element {
	const services = data.slice();
	services.sort((a, b) => a.nameEn.localeCompare(b.nameEn));
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ width: '99%' }}>Name</th>
					<th style={{ textAlign: 'right' }}>Unit</th>
					<th style={{ textAlign: 'right' }}>Value</th>
				</tr>
			</thead>
			<tbody>
				{services
					.filter(service => !service.retired)
					.map((service) => (
						<tr
							key={service.id}
							onClick={() => onClick(service)}
							style={{ cursor: 'pointer' }}
						>
							<td>{service.nameEn}</td>
							<td align="right">{service.unit}</td>
							<td align="right">{printServiceUnitPrice(service.unitPrice)}</td>
						</tr>
					))
				}
			</tbody>
		</Table>
	);
}
