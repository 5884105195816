import { Group, Text } from '@mantine/core';
import React from 'react';
import { CompanyInfo } from '../../api/types';
import OnlyPrint from '../../components/OnlyPrint';
import { useIntl } from 'react-intl';
import { DetailRow } from '../../components/DetailRow';
import DetailLabel from '../../components/DetailLabel';
import MultilineText from '../../components/MultilineText';

type Props = {
	info: CompanyInfo;
	hideInWeb?: boolean;
};

export default function Letterhead({ info, hideInWeb }: Props) {
	const intl = useIntl();
	const addressTitle = intl.formatMessage({
		id: 'YskQEM',
		defaultMessage: 'Address',
		description: 'Letterhead address label',
	});
	const telTitle = intl.formatMessage({
		id: 't9vfqI',
		defaultMessage: 'Tel',
		description: 'Letterhead tel label',
	});
	const emailTitle = intl.formatMessage({
		id: 'EtYECD',
		defaultMessage: 'Email',
		description: 'Letterhead email label',
	});
	const webTitle = intl.formatMessage({
		id: 'QYR4vZ',
		defaultMessage: 'Web',
		description: 'Letterhead web label',
	});
	const idTitle = intl.formatMessage({
		id: 'zEYkPw',
		defaultMessage: 'ID no.',
		description: 'Letterhead id label',
	});
	const pidTitle = intl.formatMessage({
		id: 'f6B7Xt',
		defaultMessage: 'PID no.',
		description: 'Letterhead pid label',
	});
	const address = intl.locale === 'bs' ? info.addressBa : info.addressEn;
	return (
		<OnlyPrint
			disabled={!hideInWeb}
		>
			<Group direction="row" sx={{ width: '100%' }}>
				<Group direction="column" grow spacing={0} sx={{ width: '100%' }}>
					<Text
						weight={300}
						sx={{ fontSize: 30 }}
					>
						{info.name}
					</Text>
					<Group direction="row" align="flex-end" position="apart">
						<Group direction="column" spacing={0}>
							<DetailLabel>{addressTitle}</DetailLabel>
							<Text size="sm">
								<MultilineText text={address.trim()} />
							</Text>
						</Group>
						<Group direction="column" spacing={0}>
							<DetailRow label={telTitle} width={50} size="sm">{info.phoneNumber}</DetailRow>
							<DetailRow label={emailTitle} width={50} size="sm">{info.email}</DetailRow>
							<DetailRow label={webTitle} width={50} size="sm">{info.website}</DetailRow>
							<DetailRow label={idTitle} width={50} size="sm">{info.governmentId}</DetailRow>
							<DetailRow label={pidTitle} width={50} size="sm">{info.taxId}</DetailRow>
						</Group>
					</Group>
				</Group>
			</Group>
		</OnlyPrint>
	);
}
