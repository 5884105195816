import { useQuery, UseQueryResult } from 'react-query';
import { useApi } from './api.hooks';
import { Currency } from '../api/types';
import { Amount } from '../domain';

const oneHourMs = 3600 * 1000;

export function useCurrencies(date: Date): UseQueryResult<Currency[]> {
	const dateString = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
	const api = useApi();
	return useQuery(`currencies.atDate.${dateString}`, async () => [
		...await api.currencies.atDate({ date: dateString }),
		{
			country: 'Bosnia and Herzegovina',
			code: 'BAM',
			units: Amount.parse('1'),
			buy: Amount.parse('1'),
			middle: Amount.parse('1'),
			sell: Amount.parse('1'),
		},
	], { staleTime: oneHourMs });
}
