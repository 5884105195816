import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Tabs } from '@mantine/core';
import { FileInvoice, Writing } from 'tabler-icons-react';
import Invoices from './Invoices';
import Quotes from './Quotes';

type Props = {
	tab: 'invoices' | 'quotes';
};

const tabIds: Record<Props['tab'], number> = {
	invoices: 0,
	quotes: 1,
};

export default function PaymentsOverview({ tab }: Props): JSX.Element {
	const navigate = useNavigate();

	const setTab = (tabId: number, tabKey: string) => navigate(`../../${tabKey}`);

	return (
		<Container>
			<Tabs active={tabIds[tab] || 0} onTabChange={setTab}>
				<Tabs.Tab tabKey="invoices" label="Invoices" icon={<FileInvoice size={14} />}>
					<Invoices />
				</Tabs.Tab>
				<Tabs.Tab tabKey="quotes" label="Quotes" icon={<Writing size={14} />}>
					<Quotes />
				</Tabs.Tab>
			</Tabs>
		</Container>
	);
};
