import { Card, Group, LoadingOverlay, Space, Text, Title, useMantineTheme } from '@mantine/core';
import { useAccounts } from '../../hooks/financialAccounts.hooks';
import { useCurrencies } from '../../hooks/currencies.hooks';
import { useStocks } from '../../hooks/stocks.hooks';
import makeBamConversions from '../../util/makeBamConversions';
import React from 'react';
import { Amount } from '../../domain';
import BaseCard from '../BaseCard';

export default function NetWorth(): JSX.Element {
	const theme = useMantineTheme();
	const {
		data: accountsData = [],
		status: accountsStatus,
	} = useAccounts();
	const { data: currencies = [], status: currencyStatus } = useCurrencies(new Date());
	const { data: stocks = {}, status: stocksStatus } = useStocks(accountsData.map((item) => item.currency));

	if (accountsStatus !== 'success' || currencyStatus !== 'success' || stocksStatus !== 'success') {
		return (
			<BaseCard
				linkTo="/accounts"
			>
				<LoadingOverlay visible />
			</BaseCard>
		);
	}

	const conversions = makeBamConversions(currencies, stocks);

	let sum = Amount.zero();
	let validSum = true;
	const currencySums = new Map<string, Amount>();

	accountsData.forEach((account) => {
		currencySums.set(
			account.currency,
			account.balance.add(currencySums.get(account.currency) || Amount.zero()),
		);
		const conversion = conversions.get(account.currency);
		if (!conversion) {
			validSum = false;
			return;
		}
		sum = sum.add(account.balance.mul(conversion).round(2));
	});

	const currencySumsArray = Array.from(currencySums);
	currencySumsArray.sort(([a, _a], [b, _b]) => a.localeCompare(b));

	return (
		<BaseCard
			linkTo="/accounts"
		>
			<Card.Section
				sx={{
					backgroundColor: theme.colors.cyan[0],
					padding: `${theme.spacing.xs}px ${theme.spacing.lg}px`,
				}}
			>
				<Title
					order={2}
					sx={{
						color: theme.colors.cyan[9],
						fontFamily: theme.other.altTitleFont,
					}}
				>
					Net Worth
				</Title>
			</Card.Section>
			<Space h="md" />
			{validSum && (
				<Group align="baseline" spacing="xs">
					<Text
						size="md"
						color="dimmed"
						sx={{
							fontFamily: theme.other.currencyFont,
						}}
					>
						BAM
					</Text>
					<Text
						size="xl"
					>
						{sum.toDotNotation(2, true)}
					</Text>
				</Group>
			)}
			{currencySumsArray.map(([key, amount]) => (
				<Group
					key={key}
					align="baseline"
					spacing="xs"
				>
					<Text
						size="xs"
						color="dimmed"
						align="right"
						sx={{
							fontFamily: theme.other.currencyFont,
							minWidth: 50,
						}}
					>
						{key}
					</Text>
					<Text
						size="sm"
						color="gray"
					>
						{amount.toDotNotation(2, !key.startsWith('$'))}
					</Text>
				</Group>
			))}
		</BaseCard>
	);
}
