import React, { useCallback } from 'react';
import { FullScreenDropzone } from '@mantine/dropzone';
import { useClients } from '../../hooks/financialClients.hooks';
import { Amount } from '../../domain';
import {
	arrayOf,
	asNumber,
	asString,
	isArray,
	isFromAs,
	isString,
	oneOf,
	optional,
	structure,
} from '@factoryfour/type-check';
import { FinancialClient } from '../../api/types';
import { InitialInvoiceState } from './types';

const asRawEntry = structure({
	issuedAt: oneOf(asString, asNumber),
	invoiceNumber: oneOf(asString, asNumber),
	fiscalYear: oneOf(asString, asNumber),
	billNumber: oneOf(asString, asNumber),
	exchangeRate: asString,
	comment: optional(asString),
	client: asString,
	items: arrayOf(structure({
		service: asString,
		unit: asString,
		amount: asString,
	})),
});

const isRawEntry = isFromAs(asRawEntry);

const parseEntry = (clients: FinancialClient[], data: unknown): InitialInvoiceState => {
	if (!isRawEntry(data)) {
		throw new Error('Bad entry');
	}
	const issuedAt = new Date(data.issuedAt);
	if (Number.isNaN(issuedAt.getTime())) {
		throw new Error('Invalid date');
	}
	const clientsFound = clients.filter((item) => item.info.name === data.client);
	if (clientsFound.length !== 1) {
		throw new Error('Ambiguous client name');
	}
	const client = clientsFound[0];
	return {
		issuedAt,
		invoiceNumber: `${data.invoiceNumber}`,
		fiscalYear: `${data.fiscalYear}`,
		billNumber: `${data.billNumber}`,
		exchangeRate: Amount.parse(data.exchangeRate),
		comment: data.comment || '',
		clientId: client.id,
		items: data.items.map((item) => {
			const servicesFound = client.services.filter((service) => !service.retired && service.nameEn === item.service && service.unit === item.unit);
			if (servicesFound.length !== 1) {
				throw new Error('Ambiguous service');
			}
			const service = servicesFound[0];
			return {
				serviceId: service.id,
				amount: Amount.parse(item.amount),
			};
		}),
	};
};

type Props = {
	addInvoices: (invoices: InitialInvoiceState[]) => void;
	disabled?: boolean;
};

export default function InvoicesJsonDrop({ addInvoices, disabled }: Props) {
	const { data: clients = [] } = useClients();

	const openFiles = useCallback((clients: FinancialClient[], files: File[]) => {
		if (files.length === 0) {
			return;
		}
		const reader = new FileReader();
		reader.addEventListener('load', (event) => {
			const data = event.target?.result;
			if (!isString(data)) {
				return;
			}
			const fileContent = JSON.parse(data);
			const entries = (isArray(fileContent) ? fileContent : [fileContent])
				.map((item) => parseEntry(clients, item));
			addInvoices(entries);
		});

		reader.readAsText(files[0]);
	}, [addInvoices]);

	return (
		<FullScreenDropzone
			key={clients.length}
			accept={['application/json']}
			onDrop={(files) => openFiles(clients, files)}
			disabled={disabled}
		>
			{() => null}
		</FullScreenDropzone>
	);
};
