import { ActionIcon } from '@mantine/core';
import React from 'react';
import { InvoiceItem, PaymentQuoteItem } from '../../api/types';
import { Trash } from 'tabler-icons-react';
import { invoiceAndQuote, printServiceUnitPrice } from '../../domain';
import { useIntl } from 'react-intl';

type Props = {
	item: InvoiceItem | PaymentQuoteItem;
	idx: number;
	remove?: () => void;
};

export function ServiceTableRow({ idx, item, remove }: Props) {
	const intl = useIntl();
	const price = invoiceAndQuote.itemPrice(item);

	return (
		<tr>
			<td align="right" style={{ whiteSpace: 'nowrap' }}>
				{idx + 1}
			</td>
			<td>
				{intl.locale === 'bs' ? item.service.nameBa : item.service.nameEn}
			</td>
			<td align="right" style={{ whiteSpace: 'nowrap' }}>
				{item.service.unit}
			</td>
			<td align="right" style={{ whiteSpace: 'nowrap' }}>
				{printServiceUnitPrice(item.service.unitPrice, intl.locale)}
			</td>
			<td align="right" style={{ whiteSpace: 'nowrap' }}>
				{invoiceAndQuote.printServiceAmount(item.amount, intl.locale)}
			</td>
			<td align="right" style={{ whiteSpace: 'nowrap' }}>
				{invoiceAndQuote.printAnyPrice(price, intl.locale)}
			</td>
			{remove && (
				<td>
					<ActionIcon
						color="red"
						size="sm"
						onClick={remove}
					>
						<Trash size={16} />
					</ActionIcon>
				</td>
			)}
		</tr>
	);
}
