import { InvoiceItem, PaymentQuoteItem } from '../api/types';
import Amount from './Amount';

export function itemPrice({ service, amount }: InvoiceItem | PaymentQuoteItem): Amount {
	return service.unitPrice.mul(amount).round(2);
}

export function overallPrice(
	items: (InvoiceItem | PaymentQuoteItem)[],
	exchangeRate: Amount,
	international: boolean,
): { original: Amount, convertedNoVat: Amount, convertedWithVat: Amount, vat: string } {
	const original = items.map(itemPrice).reduce((accum, val) => accum.add(val), Amount.zero());
	const convertedNoVat = original.mul(exchangeRate).round(2);
	const convertedWithVat = international ? convertedNoVat : convertedNoVat.mul(Amount.parse('1.17')).round(2);
	return { original, convertedNoVat, convertedWithVat, vat: international ? '0%' : '17%' };
}

export function printAnyPrice(value: Amount, language?: string): string {
	return value.toDotNotation(2, true, language === 'bs' ? ',' : '.');
}

export function printServiceAmount(value: Amount, language?: string): string {
	return value.toDotNotation(1000, false, language === 'bs' ? ',' : '.');
}
