import React from 'react';
import { PaymentQuote } from '../../api/types';
import { Table } from '@mantine/core';
import { QuotesTableRow } from './QuotesTableRow';

type Props = {
	data: PaymentQuote[];
	onClick: (client: PaymentQuote) => void;
}

export default function QuotesTable({ data, onClick }: Props): JSX.Element {
	const quotes = data.slice();
	quotes.sort((a, b) => b.issuedAt.getTime() - a.issuedAt.getTime());
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ width: '99%' }}>Client</th>
					<th style={{ textAlign: 'right' }}>Date</th>
					<th style={{ textAlign: 'right' }}>Value</th>
				</tr>
			</thead>
			<tbody>
				{quotes.map((quote) => (
					<QuotesTableRow
						key={quote.id}
						quote={quote}
						onClick={() => onClick(quote)}
					/>
				))}
			</tbody>
		</Table>
	);
}
