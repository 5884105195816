import { Button, Group, Select, TextInput } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { Amount } from '../../domain';
import { FinancialClientService } from '../../api/types';

type Props = {
	clientServices: FinancialClientService[];
	onClose: () => void;
	onSubmit: (props: {
		serviceId: string;
		amount: Amount;
	}) => void;
}

export default function AddServiceAmountForm({
	clientServices,
	onClose,
	onSubmit,
}: Props): JSX.Element {
	const form = useForm({
		initialValues: {
			serviceId: '',
			amount: '',
		},
		validate: {
			serviceId: (value) => {
				if (!clientServices.find((service) => service.id === value)) {
					return 'Invalid service selected';
				}
				return null;
			},
			amount: (value) => {
				let parseResult = Amount.parseResult(value, true);
				if (parseResult.ok) {
					return null;
				}
				return parseResult.errorMessage;
			},
		},
	});

	return (
		<form onSubmit={form.onSubmit((values) => {
			const props = {
				serviceId: values.serviceId,
				amount: Amount.parse(values.amount, true),
			};
			onSubmit(props);
			onClose();
		})}>
			<Select
				required
				label="Service Name"
				placeholder="eg. Software development service"
				data={
					clientServices
						.filter((service) => !service.retired)
						.map((service) => ({
							value: service.id,
							label: `${service.nameEn} (${service.unit})`,
						}))
						.sort((a, b) => a.label.localeCompare(b.label))
				}
				clearable
				searchable
				{...form.getInputProps('serviceId')}
			/>

			<TextInput
				required
				label="Service Amount"
				placeholder="eg. 30"
				type="number"
				{...form.getInputProps('amount')}
			/>

			<Group position="right" mt="md">
				<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
				<Button uppercase type="submit">Add</Button>
			</Group>
		</form>
	);
}
