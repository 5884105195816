import React from 'react';
import { Group, MantineSize, Text } from '@mantine/core';
import { CSSObject } from '@mantine/styles/lib/tss';
import { MantineTheme } from '@mantine/styles/lib/theme/types/MantineTheme';
import DetailLabel from './DetailLabel';

type Props = {
	label: string;
	width?: number;
	sx?: CSSObject | ((theme: MantineTheme) => CSSObject);
	size?: MantineSize;
};

export function DetailRow({ children, label, width = 60, sx, size }: React.PropsWithChildren<Props>) {
	return <Group align="baseline">
		<DetailLabel width={width}>{label}</DetailLabel>
		<Text sx={sx} size={size}>{children}</Text>
	</Group>;
}
