import { PaymentQuote } from '../../api/types';
import React from 'react';
import { Amount, invoiceAndQuote } from '../../domain';

export function QuotesTableRow({ quote, onClick }: { quote: PaymentQuote, onClick: () => void }) {
	const { convertedWithVat } = invoiceAndQuote.overallPrice(quote.items, Amount.parse('1'), quote.clientInfo.international);
	return <tr
		onClick={onClick}
		style={{ cursor: 'pointer' }}
	>
		<td>{quote.clientInfo.name}</td>
		<td
			align="right"
			style={{ whiteSpace: 'nowrap' }}
		>
			{quote.issuedAt.toLocaleDateString()}
		</td>
		<td
			align="right"
			style={{ whiteSpace: 'nowrap' }}
		>
			{`${quote.clientInfo.currency} ${invoiceAndQuote.printAnyPrice(convertedWithVat)}`}
		</td>
	</tr>;
}
