import { Button, Collapse, Group, Modal, Space, Switch, Textarea, TextInput } from '@mantine/core';
import React from 'react';
import { formList, useForm } from '@mantine/form';
import { useClients } from '../../hooks/financialClients.hooks';
import { Amount, printExchangeRate } from '../../domain';
import AddServiceAmountForm from './AddServiceAmount.form';
import { useDisclosure } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import { useCurrencies } from '../../hooks/currencies.hooks';
import { ServiceListing } from './ServiceListing';
import ClientSelect from './ClientSelect';
import { InitialInvoiceState } from '../../pages/Payments/types';

type Props = {
	initialState: InitialInvoiceState;
	onClose: () => void;
	onSubmit: (props: {
		clientId: string;
		issuedAt: Date;
		comment: string;
		invoiceNumber: string;
		fiscalYear: string;
		billNumber: string;
		exchangeRate: Amount;
		items: {
			serviceId: string;
			amount: Amount;
		}[];
	}) => void;
	skippable?: boolean;
	onSkip?: () => void;
}

export default function CreateInvoice({
	onClose,
	onSubmit,
	onSkip,
	skippable,
	initialState,
}: Props): JSX.Element {
	const [addingService, setAddingService] = useDisclosure(false);
	const [showMore, setShowMore] = useDisclosure(false);
	const { data: clients = [] } = useClients();
	const form = useForm({
		initialValues: {
			clientId: initialState.clientId || '',
			comment: initialState.comment || '',
			issuedAt: initialState.issuedAt || new Date(),
			invoiceNumber: initialState.invoiceNumber || '',
			fiscalYear: initialState.fiscalYear || '',
			billNumber: initialState.billNumber || '',
			exchangeRate: initialState.exchangeRate ? printExchangeRate(initialState.exchangeRate) : '',
			items: formList<{ serviceId: string; amount: Amount }>(initialState.items || []),
		},
		validate: {
			clientId: (value) => clients.find((client) => client.id === value) ? null : 'Required',
			exchangeRate: (value) => {
				let parseResult = Amount.parseResult(value, true);
				if (parseResult.ok) {
					return null;
				}
				return parseResult.errorMessage;
			},
		},
	});
	const { data: currencies = [] } = useCurrencies(new Date());

	const client = clients.find((client) => client.id === form.values.clientId);

	if (client && !form.values.exchangeRate) {
		const currency = currencies.find((currency) => currency.code === client.info.currency);
		if (currency) {
			form.setFieldValue('exchangeRate', printExchangeRate(currency.middle));
		}
	}

	const invalidServiceIndices: number[] = [];
	form.values.items.forEach(({ serviceId, amount }, idx) => {
		const service = client?.services.find((service) => service.id === serviceId);
		if (!service) {
			invalidServiceIndices.push(idx);
		}
	});
	if (invalidServiceIndices.length > 0) {
		form.removeListItem('items', invalidServiceIndices);
	}

	return (
		<>
			<form onSubmit={form.onSubmit((values) => {
				const client = clients.find((client) => client.id === values.clientId);
				if (!client) {
					throw new Error('Invalid client');
				}
				const props = {
					clientId: values.clientId,
					issuedAt: values.issuedAt,
					comment: values.comment.trim(),
					invoiceNumber: values.invoiceNumber,
					fiscalYear: values.fiscalYear,
					billNumber: values.billNumber,
					exchangeRate: Amount.parse(values.exchangeRate, true),
					items: values.items.map(({ serviceId, amount }) => {
						const service = client.services.find((service) => service.id === serviceId);
						if (!service) {
							throw new Error('Invalid service');
						}
						return { serviceId, amount };
					}),
				};
				onSubmit(props);
			})}>
				<ClientSelect
					required
					sx={{ maxWidth: '350px' }}
					{...form.getInputProps('clientId')}
				/>

				<Space h={8} />
				<Switch
					label="Show More"
					checked={showMore}
					onChange={setShowMore.toggle}
				/>
				<Space h={8} />

				<Collapse in={showMore}>
					<TextInput
						required
						label="Invoice #"
						placeholder="eg. 1"
						type="number"
						sx={{ maxWidth: '350px' }}
						{...form.getInputProps('invoiceNumber')}
					/>
					<TextInput
						required
						label="Fiscal Year"
						placeholder="eg. 2021"
						type="number"
						sx={{ maxWidth: '350px' }}
						{...form.getInputProps('fiscalYear')}
					/>
					<TextInput
						required
						label="Bill Number"
						placeholder="eg. 54"
						type="number"
						sx={{ maxWidth: '350px' }}
						{...form.getInputProps('billNumber')}
					/>
					<TextInput
						required
						label="Exchange Rate"
						placeholder="eg. 1.8102"
						sx={{ maxWidth: '350px' }}
						{...form.getInputProps('exchangeRate')}
					/>
					<DatePicker
						required
						label="Issued At"
						placeholder="Pick date"
						clearable={false}
						sx={{
							maxWidth: '350px',
						}}
						{...form.getInputProps('issuedAt')}
					/>
					<Textarea
						label="Comment"
						placeholder="eg. Handled issue #742"
						minRows={2}
						autosize
						{...form.getInputProps('comment')}
					/>
				</Collapse>

				<ServiceListing
					client={client}
					items={form.values.items}
					remove={(indices) => form.removeListItem('items', indices)}
					add={setAddingService.open}
					exchangeRate={form.values.exchangeRate}
				/>

				<Group position="right" mt="md">
					<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
					{skippable && onSkip && (
						<Button uppercase variant="subtle" color="gray" onClick={onSkip}>Skip</Button>
					)}
					<Button uppercase type="submit">
						Create
					</Button>
				</Group>
			</form>
			{addingService && (
				<Modal
					opened
					onClose={setAddingService.close}
					title="Add Invoice Entry"
				>
					<AddServiceAmountForm
						clientServices={client?.services || []}
						onClose={setAddingService.close}
						onSubmit={(value) => form.addListItem('items', value)}
					/>
				</Modal>
			)}
		</>
	);
}
