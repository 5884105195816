import { Icon } from 'tabler-icons-react';
import { Button, DefaultMantineColor } from '@mantine/core';
import React from 'react';

type Props = {
	onClick: () => void;
	color?: DefaultMantineColor;
	icon?: Icon;
	disabled?: boolean;
}

export default function QuickButton({
	color = 'blue',
	children,
	onClick,
	icon: Icon,
	disabled,
}: React.PropsWithChildren<Props>): JSX.Element {
	return <Button
		variant="subtle"
		color={color}
		leftIcon={Icon && <Icon size={14} strokeWidth={2} />}
		onClick={onClick}
		compact
		size="sm"
		disabled={disabled}
	>
		{children}
	</Button>;
}
