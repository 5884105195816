import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, LoadingOverlay, Menu, Modal, Select } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';
import { useInvoices } from '../../hooks/payments.hooks';
import InvoicesTable from '../../layouts/payments/InvoicesTable';
import CreateInvoiceForm from '../../layouts/payments/CreateInvoice.form';
import { useApi } from '../../hooks/api.hooks';
import { useSimpleMutation } from '../../queryUtils';
import InvoicesJsonDrop from './InvoicesJsonDrop';
import { InitialInvoiceState } from './types';
import { useKeyedQueue } from '../../hooks/util.hooks';

export default function Invoices(): JSX.Element {
	const api = useApi();
	const {
		data: invoicesData = [],
		status: invoicesStatus,
	} = useInvoices();
	const navigate = useNavigate();
	const addInvoice = useSimpleMutation(api.finance.invoices.addInvoice);
	const [clientFilter, setClientFilter] = useState<string | null>(null);
	const [yearFilter, setYearFilter] = useState<string | null>(() => (new Date()).getFullYear().toString());
	const invoiceAddQueue = useKeyedQueue<InitialInvoiceState>();

	if (invoicesStatus !== 'success') {
		return <LoadingOverlay visible />;
	}

	const addOneInvoice = () => {
		const invoiceState: InitialInvoiceState = {
			fiscalYear: `${new Date().getFullYear()}`,
			invoiceNumber: '1',
			billNumber: '1',
		};
		if (invoicesData.length > 0) {
			const newestInvoice = invoicesData.reduce((accum, val) => accum.issuedAt.getTime() < val.issuedAt.getTime() ? val : accum);
			if (newestInvoice.fiscalYear === invoiceState.fiscalYear) {
				invoiceState.invoiceNumber = `${(parseInt(newestInvoice.invoiceNumber) || 0) + 1}`;
			}
			invoiceState.billNumber = `${(parseInt(newestInvoice.billNumber) || 0) + 1}`;
		}
		invoiceAddQueue.set([invoiceState]);
	};

	const clientMap = new Map<string, { value: string; label: string }>();
	const fiscalYearSet = new Set<string>();
	invoicesData.forEach((invoice) => {
		clientMap.set(invoice.clientId, { value: invoice.clientId, label: invoice.clientInfo.name });
		fiscalYearSet.add(invoice.fiscalYear);
	});

	return (
		<>
			<Group
				position="apart"
				style={{
					minHeight: '28px',
				}}
			>
				<Menu
					closeOnItemClick={false}
					styles={{
						itemHovered: { backgroundColor: 'transparent', cursor: 'inherit' },
					}}
				>
					<Menu.Label>Client</Menu.Label>
					<Menu.Item pt={2}>
						<Select
							value={clientFilter}
							clearable
							searchable
							data={Array.from(clientMap.values()).sort((a, b) => a.label.localeCompare(b.label))}
							size="xs"
							onChange={setClientFilter}
						/>
					</Menu.Item>
					<Menu.Label>Year</Menu.Label>
					<Menu.Item pt={2}>
						<Select
							value={yearFilter}
							clearable
							searchable
							data={Array.from(fiscalYearSet.values()).sort()}
							size="xs"
							onChange={setYearFilter}
						/>
					</Menu.Item>
				</Menu>
				<QuickButton
					icon={Plus}
					onClick={addOneInvoice}
					disabled={invoiceAddQueue.queue.length > 0}
				>
					Add Invoice
				</QuickButton>
			</Group>
			<InvoicesTable
				data={invoicesData}
				onClick={(invoice) => navigate(invoice.id)}
				clientId={clientFilter || undefined}
				fiscalYear={yearFilter || undefined}
			/>
			<InvoicesJsonDrop
				addInvoices={invoiceAddQueue.set}
				disabled={invoiceAddQueue.queue.length > 0}
			/>
			<Modal
				opened={invoiceAddQueue.queue.length > 0}
				onClose={invoiceAddQueue.clear}
				title="Add Invoice"
				size="full"
			>
				<CreateInvoiceForm
					key={invoiceAddQueue.key}
					initialState={invoiceAddQueue.queue[0] || {}}
					onClose={invoiceAddQueue.clear}
					skippable={invoiceAddQueue.queue.length > 1}
					onSkip={invoiceAddQueue.shift}
					onSubmit={(invoice) => {
						addInvoice(invoice);
						invoiceAddQueue.shift();
					}}
				/>
			</Modal>
		</>
	);
};
