import { useReducer } from 'react';

export type KeyedQueueState<T> = {
	key: number,
	queue: T[],
};

type KeyedQueueAction<T> = { type: 'shift' | 'set', queue: T[] };

type KeyedQueueReducer<T> = (prev: KeyedQueueState<T>, action: KeyedQueueAction<T>) => KeyedQueueState<T>;

const keyedQueueReducer = <T>(prev: KeyedQueueState<T>, action: KeyedQueueAction<T>): KeyedQueueState<T> => {
	let queue: T[];
	switch (action.type) {
		case 'shift':
			queue = prev.queue.slice();
			queue.shift();
			break;
		case 'set':
			queue = action.queue;
			break;
		default:
			queue = prev.queue;
			break;
	}
	return {
		key: prev.key + 1,
		queue,
	};
};

export const useKeyedQueue = <T>(initialQueue: T[] = []): { queue: T[]; key: number; set: (items: T[]) => void; shift: () => void; clear: () => void } => {
	const [{ queue, key }, dispatch] = useReducer<KeyedQueueReducer<T>>(keyedQueueReducer, {
		queue: initialQueue,
		key: 1,
	});
	return {
		queue,
		key,
		set: (value: T[]) => dispatch({ type: 'set', queue: value }),
		shift: () => dispatch({ type: 'shift', queue: [] }),
		clear: () => dispatch({ type: 'set', queue: [] }),
	};
};
