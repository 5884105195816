import { MutationFunction, QueryClient, useMutation, useQueryClient } from 'react-query';

export const refreshAll = (queryClient: QueryClient) => ({
	onSuccess: () => {
		queryClient.invalidateQueries();
	},
});

export const useSimpleMutation = <T, U>(mutation: MutationFunction<T, U>) => {
	const queryClient = useQueryClient();
	return useMutation(
		mutation,
		{
			onSuccess: () => {
				queryClient.invalidateQueries();
			},
		},
	).mutate;
};
