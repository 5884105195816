import { Button, Group, Select, Text, TextInput } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { FinancialAccount } from '../../api/types';
import { useModals } from '@mantine/modals';
import { Trash } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';

type Props = {
	account?: FinancialAccount;
	authorities: string[];
	onClose: () => void;
	onSubmit: (props: {
		name: string;
		group: string;
		currency: string;
	}) => void;
	onDelete?: () => void;
}

export default function UpsertAccount({
	authorities,
	account,
	onClose,
	onSubmit,
	onDelete,
}: Props): JSX.Element {
	const modals = useModals();
	const form = useForm({
		initialValues: {
			name: account?.name || '',
			group: account?.group || '',
			currency: account?.currency || '',
		},
		validate: {
			name: (value) => value.trim() !== '' ? null : 'Required',
			group: (value) => value.trim() !== '' ? null : 'Required',
			currency: (value) => value.trim() !== '' ? null : 'Required',
		},
	});

	const openDeleteModal = () => modals.openConfirmModal({
		title: 'Close Financial Account',
		children: (
			<Text>{`Are you sure you want to close account "${account?.name}"?`}</Text>
		),
		labels: { confirm: 'Delete', cancel: 'Cancel' },
		confirmProps: {
			color: 'red',
			uppercase: true,
		},
		cancelProps: {
			color: 'gray',
			variant: 'subtle',
			uppercase: true,
		},
		onConfirm: () => {
			onDelete && onDelete();
			onClose();
		},
	});

	const authoritiesSet = new Set<string>();
	authorities.forEach((item) => authoritiesSet.add(item));
	if (form.values.group) {
		authoritiesSet.add(form.values.group);
	}

	return (
		<form onSubmit={form.onSubmit((values) => {
			let props = {
				name: values.name.trim(),
				group: values.group.trim(),
				currency: values.currency.trim(),
			};
			onSubmit(props);
			onClose();
		})}>
			<TextInput
				required
				label="Name"
				placeholder="eg. Savings Account"
				{...form.getInputProps('name')}
			/>

			<Select
				required
				label="Authority"
				placeholder="eg. My Bank Name"
				data={Array.from(authoritiesSet.values()).sort()}
				clearable
				searchable
				creatable
				getCreateLabel={(value) => `+ ${value}`}
				{...form.getInputProps('group')}
			/>

			<TextInput
				required
				label="Currency"
				placeholder="eg. USD, $SPY"
				{...form.getInputProps('currency')}
			/>

			<Group position="apart" mt="md">
				<Group>
					{account && (
						<QuickButton icon={Trash} color="red" onClick={openDeleteModal}>
							Close Account
						</QuickButton>
					)}
				</Group>
				<Group>
					<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
					<Button uppercase type="submit">{account ? 'Update' : 'Add'}</Button>
				</Group>
			</Group>
		</form>
	);
}
