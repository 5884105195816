import { Box, Group, Text, ThemeIcon } from '@mantine/core';
import { Link, useLocation } from 'react-router-dom';

type Props = {
	icon: React.ReactNode;
	color: string;
	label: string;
	to: string;
	onClick: () => void;
};

export default function NavigationLink({ icon, color, label, to, onClick }: Props) {
	const location = useLocation();
	const pathMatches = location.pathname.startsWith(to);
	return (
		<Link
			to={to}
			style={{
				width: '100%',
				textDecoration: 'none',
				boxSizing: 'border-box',
			}}
			onClick={onClick}
		>
			<Box
				sx={(theme) => ({
					display: 'block',
					boxSizing: 'border-box',
					width: '100%',
					padding: theme.spacing.xs,
					borderRadius: theme.radius.sm,
					color: theme.black,
					backgroundColor: pathMatches ? theme.colors.gray[2] : undefined,

					'&:hover': {
						backgroundColor: theme.colors.gray[0],
					},
				})}
			>
				<Group>
					<ThemeIcon color={color} variant="light">
						{icon}
					</ThemeIcon>

					<Text size="sm">
						{label}
					</Text>
				</Group>
			</Box>
		</Link>
	);
}
