import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Group, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useAccounts } from '../../hooks/financialAccounts.hooks';
import AccountsTable from '../../layouts/financialAccounts/AccountsTable';
import { useCurrencies } from '../../hooks/currencies.hooks';
import { useStocks } from '../../hooks/stocks.hooks';
import { Plus } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';
import UpsertAccountForm from '../../layouts/financialAccounts/UpsertAccount.form';
import { useModals } from '@mantine/modals';
import { useApi } from '../../hooks/api.hooks';
import { useSimpleMutation } from '../../queryUtils';

export default function Finances(): JSX.Element {
	const api = useApi();
	const modals = useModals();
	const addAccount = useSimpleMutation(api.finance.accounts.add);
	const {
		data: accountsData = [],
		status: accountsStatus,
	} = useAccounts();
	const { data: currencies = [], status: currencyStatus } = useCurrencies(new Date());
	const { data: stocks = {}, status: stocksStatus } = useStocks(accountsData.map((item) => item.currency));
	const navigate = useNavigate();

	if (accountsStatus !== 'success' || currencyStatus !== 'success' || stocksStatus !== 'success') {
		return <LoadingOverlay visible />;
	}

	const openAddModal = () => {
		const id = modals.openModal({
			title: 'Add Financial Account',
			children: (
				<UpsertAccountForm
					authorities={accountsData.map((item) => item.group)}
					onClose={() => modals.closeModal(id)}
					onSubmit={addAccount}
				/>
			),
		});
	};

	return (
		<ScrollArea sx={{ height: '100%' }}>
			<Container>
				<Group position="right">
					<QuickButton
						icon={Plus}
						onClick={openAddModal}
					>
						Add Account
					</QuickButton>
				</Group>
				<AccountsTable
					data={accountsData}
					currencies={currencies}
					stocks={stocks}
					onClick={(account) => navigate(account.id)}
				/>
			</Container>
		</ScrollArea>
	);
};
