import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Api, ApiContext } from './api';
import config from './config';
import { LoadingOverlay, MantineProvider } from '@mantine/core';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ModalsProvider } from '@mantine/modals';
import { HashRouter as Router } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import localeMessages from './localeMessages';

const queryClient = new QueryClient();

export default function AppProviders({ children }: React.PropsWithChildren<{}>) {
	const {
		isAuthenticated,
		isLoading,
		getAccessTokenSilently,
		loginWithRedirect,
	} = useAuth0();
	const [{ status, api }, setApi] = useState<{ status: 'missing' | 'loading' | 'ready', api: Api }>({
		status: 'missing',
		api: new Api('', ''),
	});

	useEffect(() => {
		if (!isAuthenticated) {
			if (!isLoading) {
				loginWithRedirect({ connection: 'ursadev-git' });
			}
			return;
		}

		if (status !== 'missing') {
			return;
		}

		setApi({ status: 'loading', api });
		getAccessTokenSilently().then((token) => setApi({ status: 'ready', api: new Api(config.apiPath, token) }));
	}, [api, getAccessTokenSilently, isAuthenticated, isLoading, loginWithRedirect, status]);

	if (status !== 'ready') {
		return <LoadingOverlay visible />;
	}

	const fonts = {
		sansitaSwashed: `'Sansita Swashed', cursive`,
		fredoka: `'Fredoka', sans-serif`,
		roboto: `'Roboto', sans-serif`,
	};
	return (
		<IntlProvider
			locale="en"
			defaultLocale="en"
			messages={localeMessages.en}
		>
			<QueryClientProvider client={queryClient}>
				<ApiContext.Provider value={api}>
					<Router>
						<MantineProvider
							theme={{
								fontFamily: fonts.roboto,
								headings: {
									fontFamily: fonts.roboto,
									fontWeight: 300,
								},
								other: {
									altTitleFont: fonts.fredoka,
									currencyFont: fonts.fredoka,
									brandColors: {
										dark: '#23425e',
										light: '#eceff1',
										red: '#d32f2f',
										blue: '#0097a7',
										blueLight: '#4dd0e1',
										yellow: '#FFC400',
									},
								},
							}}
						>
							<ModalsProvider>
								{children}
							</ModalsProvider>
						</MantineProvider>
					</Router>
				</ApiContext.Provider>
			</QueryClientProvider>
		</IntlProvider>
	);
}
