import { useQuery, UseQueryResult } from 'react-query';
import { useApi } from './api.hooks';
import { CompanyInfo } from '../api/types';

const oneHourMs = 3600 * 1000;

export function useCompanyInfo(): UseQueryResult<CompanyInfo> {
	const api = useApi();
	return useQuery(`companyInfo`, api.companyInfo.get, { staleTime: oneHourMs });
}
