import React from 'react';
import { Card } from '@mantine/core';
import { Link } from 'react-router-dom';

type Props = {
	linkTo: string;
}

export default function BaseCard({ children, linkTo }: React.PropsWithChildren<Props>): JSX.Element {
	return (
		<Card
			withBorder
			sx={{
				minHeight: 120,
				minWidth: 320,
				maxWidth: 480,
				flex: '1 1 0',
			}}
			radius="md"
			p="md"
			component={Link}
			to={linkTo}
		>
			{children}
		</Card>
	);
}
