import Amount from './Amount';

export function printServiceUnitPrice(value: Amount, language?: string): string {
	const shortPrice = value.toDotNotation(2, false, language === 'bs' ? ',' : '.');
	const longPrice = value.toDotNotation(1000, false, language === 'bs' ? ',' : '.');
	if (shortPrice !== longPrice) {
		return longPrice;
	}
	return value.toDotNotation(2, true, language === 'bs' ? ',' : '.');
}
