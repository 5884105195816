import React from 'react';
import { FinancialClient } from '../../api/types';
import { Table } from '@mantine/core';

type Props = {
	data: FinancialClient[];
	onClick: (client: FinancialClient) => void;
}

export default function ClientsTable({ data, onClick }: Props): JSX.Element {
	const clients = data.slice();
	clients.sort((a, b) => a.info.name.localeCompare(b.info.name));
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ width: '99%' }}>Name</th>
				</tr>
			</thead>
			<tbody>
				{clients.map((client) => (
					<tr
						key={client.id}
						onClick={() => onClick(client)}
						style={{ cursor: 'pointer' }}
					>
						<td>{client.info.name}</td>
					</tr>
				))}
			</tbody>
		</Table>
	);
}
