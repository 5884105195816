import { Button, Group, Select, TextInput } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { Amount } from '../../domain';
import { useClients } from '../../hooks/financialClients.hooks';

type Props = {
	clientId: string;
	onClose: () => void;
	onSubmit: (props: {
		clientId: string;
		nameEn: string;
		nameBa: string;
		unit: string;
		unitPrice: Amount;
	}) => void;
}

export default function InsertClientService({
	clientId,
	onClose,
	onSubmit,
}: Props): JSX.Element {
	const { data: clients } = useClients();
	const namesEnSet = new Set<string>();
	const namesBaSet = new Set<string>();
	(clients || []).forEach((client) => client.services.forEach((service) => {
		namesEnSet.add(service.nameEn);
		namesBaSet.add(service.nameBa);
	}));
	const form = useForm({
		initialValues: {
			nameEn: '',
			nameBa: '',
			unit: '',
			unitPrice: '',
		},
		validate: {
			nameEn: (value) => value.trim() !== '' ? null : 'Required',
			nameBa: (value) => value.trim() !== '' ? null : 'Required',
			unit: (value) => value.trim() !== '' ? null : 'Required',
			unitPrice: (value) => {
				let parseResult = Amount.parseResult(value, true);
				if (parseResult.ok) {
					return null;
				}
				return parseResult.errorMessage;
			},
		},
	});
	if (form.values.nameBa) {
		namesBaSet.add(form.values.nameBa);
	}
	if (form.values.nameEn) {
		namesEnSet.add(form.values.nameEn);
	}

	return (
		<form onSubmit={form.onSubmit((values) => {
			const props = {
				clientId,
				nameEn: values.nameEn.trim(),
				nameBa: values.nameBa.trim(),
				unit: values.unit.trim(),
				unitPrice: Amount.parse(values.unitPrice, true),
			};
			onSubmit(props);
			onClose();
		})}>
			<Select
				required
				label="English Name"
				placeholder="eg. Software development service"
				data={Array.from(namesEnSet.values()).sort()}
				clearable
				searchable
				creatable
				getCreateLabel={(value) => `+ ${value}`}
				{...form.getInputProps('nameEn')}
			/>

			<Select
				required
				label="Bosnian Name"
				placeholder="eg. Usluga programiranja"
				data={Array.from(namesBaSet.values()).sort()}
				clearable
				searchable
				creatable
				getCreateLabel={(value) => `+ ${value}`}
				{...form.getInputProps('nameBa')}
			/>

			<TextInput
				required
				label="Unit"
				placeholder="eg. h, m, L"
				{...form.getInputProps('unit')}
			/>

			<TextInput
				required
				label="Unit Price"
				placeholder="eg. 30.00"
				type="number"
				{...form.getInputProps('unitPrice')}
			/>

			<Group position="right" mt="md">
				<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
				<Button uppercase type="submit">Add</Button>
			</Group>
		</form>
	);
}
