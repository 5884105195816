import axios, { AxiosRequestConfig } from 'axios';
import {
	BalanceAt,
	CompanyInfo,
	Currency,
	FinancialAccount,
	FinancialAccountHistory,
	FinancialClient,
	FinancialClientService,
	Invoice,
	PaymentQuote,
	Stock,
} from './types';
import { Amount } from '../domain';
import {
	RawCompanyInfoV1,
	RawCurrency,
	RawFinancialAccount,
	RawFinancialClient,
	RawInvoice,
	RawPaymentQuote,
	RawStock,
	toBalanceAt,
	toCompanyInfoFromV1,
	toCurrency,
	toFinancialAccount,
	toFinancialAccountHistory,
	toFinancialClient,
	toFinancialClientService,
	toInvoice,
	toPaymentQuote,
	toStock,
} from './rawTypes';

export default class Api {
	private readonly apiPath: string;

	private config: AxiosRequestConfig;

	constructor(apiPath: string, token: string) {
		this.apiPath = apiPath;
		this.config = {
			headers: { Authorization: `Bearer ${token}` },
		};
	}

	url = (path: string): string => `${this.apiPath}${path}`;

	crud = {
		get: async <T>(
			path: string,
			query?: { [key: string]: string },
		): Promise<T> => (await axios.get<{ value: T }>(this.url(path), { ...this.config, params: query })).data.value,

		post: async <T>(
			path: string,
			data: unknown,
		): Promise<T> => (await axios.post<{ value: T }>(this.url(path), data, this.config)).data.value,

		put: async <T>(
			path: string,
			data: unknown,
		): Promise<T> => (await axios.put<{ value: T }>(this.url(path), data, this.config)).data.value,

		delete: async <T>(
			path: string,
		): Promise<T> => (await axios.delete<{ value: T }>(this.url(path), this.config)).data.value,
	};

	finance = {
		accounts: {
			getAll: async (): Promise<FinancialAccount[]> => (await this.crud.get<RawFinancialAccount[]>('/finance/accounts')).map(toFinancialAccount),

			add: async ({
				name,
				group,
				currency,
			}: { name: string, group: string, currency: string }): Promise<FinancialAccount> =>
				toFinancialAccount(await this.crud.post('/finance/accounts', {
					name,
					group,
					currency,
				})),

			updateAccount: async ({
				accountId,
				name,
				group,
				currency,
			}: { accountId: string, name: string, group: string, currency: string }): Promise<FinancialAccount> =>
				toFinancialAccount(await this.crud.put(`/finance/accounts/${accountId}`, {
					name,
					group,
					currency,
				})),

			deleteAccount: async ({
				accountId,
			}: { accountId: string }): Promise<boolean> =>
				await this.crud.delete(`/finance/accounts/${accountId}`),

			getAccount: async ({ accountId }: { accountId: string }): Promise<FinancialAccount> =>
				toFinancialAccount(await this.crud.get(`/finance/accounts/${accountId}`)),

			getHistory: async ({ accountId }: { accountId: string }): Promise<FinancialAccountHistory> =>
				toFinancialAccountHistory(await this.crud.get(`/finance/accounts/${accountId}/history`)),

			setBalance: async ({
				accountId,
				balance,
			}: { accountId: string, balance: Amount }): Promise<BalanceAt> =>
				toBalanceAt(await this.crud.put(`/finance/accounts/${accountId}/balance`, { balance: balance.toRatio() })),
		},

		clients: {
			getAll: async (): Promise<FinancialClient[]> => (await this.crud.get<RawFinancialClient[]>('/finance/clients')).map(toFinancialClient),

			addClient: async ({
				name,
				currency,
				businessId,
				taxId,
				address,
				international,
			}: {
				name: string;
				currency: string;
				businessId: string;
				taxId: string;
				address: string;
				international: boolean;
			}): Promise<FinancialClient> =>
				toFinancialClient(await this.crud.post('/finance/clients', {
					name,
					currency,
					businessId,
					taxId,
					address,
					international,
				})),

			updateClient: async ({
				clientId,
				name,
				currency,
				businessId,
				taxId,
				address,
				international,
			}: {
				clientId: string;
				name: string;
				currency: string;
				businessId: string;
				taxId: string;
				address: string;
				international: boolean;
			}): Promise<FinancialClient> =>
				toFinancialClient(await this.crud.put(`/finance/clients/${clientId}`, {
					name,
					currency,
					businessId,
					taxId,
					address,
					international,
				})),

			deleteClient: async ({
				clientId,
			}: { clientId: string }): Promise<boolean> =>
				await this.crud.delete(`/finance/clients/${clientId}`),

			getClient: async ({ clientId }: { clientId: string }): Promise<FinancialClient> =>
				toFinancialClient(await this.crud.get(`/finance/clients/${clientId}`)),

			addService: async ({
				clientId,
				nameEn,
				nameBa,
				unit,
				unitPrice,
			}: {
				clientId: string;
				nameEn: string;
				nameBa: string;
				unit: string;
				unitPrice: Amount;
			}): Promise<FinancialClientService> =>
				toFinancialClientService(await this.crud.post(`/finance/clients/${clientId}/services`, {
					clientId,
					nameEn,
					nameBa,
					unit,
					unitPrice: unitPrice.toRatio(),
				})),

			retireService: async ({
				clientId,
				serviceId,
			}: {
				clientId: string;
				serviceId: string;
			}): Promise<boolean> =>
				await this.crud.delete(`/finance/clients/${clientId}/services/${serviceId}`),
		},

		invoices: {
			getAll: async (): Promise<Invoice[]> => (await this.crud.get<RawInvoice[]>('/finance/invoices')).map(toInvoice),

			addInvoice: async ({
				issuedAt,
				invoiceNumber,
				fiscalYear,
				billNumber,
				exchangeRate,
				comment,
				clientId,
				items,
			}: {
				issuedAt: Date;
				invoiceNumber: string;
				fiscalYear: string;
				billNumber: string;
				exchangeRate: Amount;
				comment: string;
				clientId: string;
				items: {
					serviceId: string;
					amount: Amount;
				}[];
			}): Promise<Invoice> =>
				toInvoice(await this.crud.post('/finance/invoices', {
					issuedAt: issuedAt.toISOString(),
					invoiceNumber,
					fiscalYear,
					billNumber,
					exchangeRate: exchangeRate.toRatio(),
					comment,
					clientId,
					items: items.map(({ serviceId, amount }) => ({
						serviceId,
						amount: amount.toRatio(),
					})),
				})),

			deleteInvoice: async ({
				invoiceId,
			}: { invoiceId: string }): Promise<boolean> =>
				await this.crud.delete(`/finance/invoices/${invoiceId}`),

			getInvoice: async ({ invoiceId }: { invoiceId: string }): Promise<Invoice> =>
				toInvoice(await this.crud.get(`/finance/invoices/${invoiceId}`)),
		},

		quotes: {
			getAll: async (): Promise<PaymentQuote[]> => (await this.crud.get<RawPaymentQuote[]>('/finance/quotes')).map(toPaymentQuote),

			addQuote: async ({
				issuedAt,
				comment,
				clientId,
				items,
			}: {
				issuedAt: Date;
				comment: string;
				clientId: string;
				items: {
					serviceId: string;
					amount: Amount;
				}[];
			}): Promise<PaymentQuote> =>
				toPaymentQuote(await this.crud.post('/finance/quotes', {
					issuedAt: issuedAt.toISOString(),
					comment,
					clientId,
					items: items.map(({ serviceId, amount }) => ({
						serviceId,
						amount: amount.toRatio(),
					})),
				})),

			deleteQuote: async ({
				quoteId,
			}: { quoteId: string }): Promise<boolean> =>
				await this.crud.delete(`/finance/quotes/${quoteId}`),

			getQuote: async ({ quoteId }: { quoteId: string }): Promise<PaymentQuote> =>
				toPaymentQuote(await this.crud.get(`/finance/quotes/${quoteId}`)),
		},
	};

	currencies = {
		atDate: async ({ date }: { date: string }): Promise<Currency[]> =>
			(await this.crud.get<RawCurrency[]>('/currencies', { date })).map(toCurrency),
	};

	stocks = {
		lastClose: async ({ symbol }: { symbol: string }): Promise<Stock> =>
			toStock(await this.crud.get<RawStock>('/stocks/lastclose', { symbol })),
	};

	companyInfo = {
		get: async (): Promise<CompanyInfo> => toCompanyInfoFromV1(await this.crud.get<RawCompanyInfoV1>('/company_info/v1')),
	};
}
