import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { AppShell, Burger, Header, Image, MediaQuery, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Finances from './pages/Finances';
import FinancialAccountDetails from './pages/Finances/FinancialAccountDetails';
import Overview from './pages/Overview';
import Clients from './pages/Clients/Clients';
import ClientDetails from './pages/Clients/ClientDetails';
import PaymentsOverview from './pages/Payments/PaymentsOverview';
import Navigation from './Navigation';
import InvoiceDetails from './pages/Payments/InvoiceDetails';
import QuoteDetails from './pages/Payments/QuoteDetails';
import NoPrint from './components/NoPrint';

function App() {
	const [opened, openedControl] = useDisclosure(false);
	const theme = useMantineTheme();

	return (
		<AppShell
			header={
				<NoPrint>
					<Header
						height={70}
						p="md"
						sx={{
							backgroundColor: theme.other.brandColors.dark,
						}}
					>
						<div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
							<MediaQuery largerThan="sm" styles={{ display: 'none' }}>
								<Burger
									opened={opened}
									onClick={openedControl.toggle}
									size="sm"
									color={theme.colors.gray[3]}
									mr="xl"
								/>
							</MediaQuery>
							<Link to="/">
								<Image
									src="/logo_and_text_100h.png"
									alt="Ursa Dev Logo"
									height={50}
									fit="contain"
								/>
							</Link>
						</div>
					</Header>
				</NoPrint>
			}
			navbar={<Navigation hidden={!opened} hide={openedControl.close} />}
			navbarOffsetBreakpoint="sm"
			fixed
			styles={{
				main: {
					height: '100vh',
				},
			}}
		>
			<Routes>
				<Route path="/" element={<Navigate to="/overview" />} />
				<Route path="/overview" element={<Overview />} />
				<Route path="/accounts">
					<Route index element={<Finances />} />
					<Route path=":accountId" element={<FinancialAccountDetails />} />
				</Route>
				<Route path="/clients">
					<Route index element={<Clients />} />
					<Route path=":clientId" element={<ClientDetails />} />
				</Route>
				<Route path="/payments">
					<Route index element={<Navigate to="invoices" />} />
					<Route path="invoices">
						<Route index element={<PaymentsOverview tab="invoices" />} />
						<Route path=":invoiceId" element={<InvoiceDetails />} />
					</Route>
					<Route path="quotes">
						<Route index element={<PaymentsOverview tab="quotes" />} />
						<Route path=":quoteId" element={<QuoteDetails />} />
					</Route>
				</Route>
			</Routes>
		</AppShell>
	);
}

export default App;
