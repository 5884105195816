import { Select, SelectProps } from '@mantine/core';
import { useClients } from '../../hooks/financialClients.hooks';
import React, { useMemo } from 'react';

type Props = Omit<SelectProps, 'data'>;

export default function ClientSelect(props: Props) {
	const { data: clients = [] } = useClients();
	let data = useMemo(
		() => clients
			.map((client) => ({
				value: client.id,
				label: client.info.name,
			}))
			.sort((a, b) => a.label.localeCompare(b.label)),
		[clients],
	);
	return (
		<Select
			label="Client"
			placeholder="eg. Big Company LLC"
			clearable
			searchable
			{...props}
			data={data}
		/>
	);
}
