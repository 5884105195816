import React from 'react';
import { Group, Text } from '@mantine/core';
import { CSSObject } from '@mantine/styles/lib/tss';
import { MantineTheme } from '@mantine/styles/lib/theme/types/MantineTheme';

type Props = {
	label: string;
	width?: number;
	sx?: CSSObject | ((theme: MantineTheme) => CSSObject);
};

export default function ServiceSumRow({ children, label, width = 90, sx }: React.PropsWithChildren<Props>) {
	return <Group align="baseline">
		<Text
			size="sm"
			weight={500}
			sx={{ minWidth: width }}
		>
			{label}
		</Text>
		<Text sx={sx}>{children}</Text>
	</Group>;
}
