import { Invoice, PaymentQuote } from '../api/types';
import { useApi } from './api.hooks';
import { useQuery, UseQueryResult } from 'react-query';

export function useInvoices(): UseQueryResult<Invoice[], unknown> {
	const api = useApi();
	return useQuery('finance.invoices.getAll', api.finance.invoices.getAll);
}

export function useQuotes(): UseQueryResult<PaymentQuote[], unknown> {
	const api = useApi();
	return useQuery('finance.quotes.getAll', api.finance.quotes.getAll);
}
