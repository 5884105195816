import { useQuery, UseQueryResult } from 'react-query';
import { useApi } from './api.hooks';
import { Stock } from '../api/types';

const oneHourMs = 3600 * 1000;

export function useStock(symbol: string): UseQueryResult<Stock | null> {
	const api = useApi();
	return useQuery(`stocks.lastClose.${symbol}`, () => {
		if (!symbol.startsWith('$')) {
			return null;
		}
		return api.stocks.lastClose({ symbol: symbol.substring(1) });
	}, { staleTime: oneHourMs });
}

export function useStocks(symbols: string[]): UseQueryResult<{ [key: string]: Stock }> {
	const api = useApi();
	return useQuery(`stocks.lastClose.${JSON.stringify(symbols)}`, async () => {
		const output: { [key: string]: Stock } = {};
		(await Promise.all(
				symbols
					.filter((symbol) => symbol.startsWith('$'))
					.map(async (symbol) => ({
						symbol,
						stock: await api.stocks.lastClose({ symbol: symbol.substring(1) }),
					})))
		).forEach((item) => {
			output[item.symbol] = item.stock;
		});
		return output;
	}, { staleTime: oneHourMs });
}
