import React from 'react';
import { Text } from '@mantine/core';

export default function DetailLabel({ children, width }: React.PropsWithChildren<{ width?: number }>) {
	return <Text
		size="sm"
		color="dimmed"
		sx={{ minWidth: width }}
	>
		{children}
	</Text>;
}
