import React from 'react';
import { Invoice } from '../../api/types';
import { Table, useMantineTheme } from '@mantine/core';
import { Amount, invoiceAndQuote } from '../../domain';
import { InvoicesTableRow } from './InvoicesTableRow';

type Props = {
	data: Invoice[];
	onClick: (client: Invoice) => void;
	fiscalYear?: string;
	clientId?: string;
}

export default function InvoicesTable({ data, onClick, fiscalYear, clientId }: Props): JSX.Element {
	const theme = useMantineTheme();
	let invoices = data.slice();
	if (clientId) {
		invoices = invoices.filter((invoice) => invoice.clientId === clientId);
	}
	if (fiscalYear) {
		invoices = invoices.filter((invoice) => invoice.fiscalYear === fiscalYear);
	}
	invoices.sort((a, b) => b.issuedAt.getTime() - a.issuedAt.getTime());
	let sum = invoices
		.map((invoice) => invoiceAndQuote.overallPrice(invoice.items, invoice.exchangeRate, invoice.clientInfo.international).convertedWithVat)
		.reduce((accum, val) => accum.add(val), Amount.zero());
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ width: '99%' }}>Client</th>
					<th style={{ textAlign: 'right' }}>ID</th>
					<th style={{ textAlign: 'right' }}>Date</th>
					<th style={{ textAlign: 'right' }}>Value</th>
				</tr>
			</thead>
			<tbody>
				{invoices.map((invoice) => (
					<InvoicesTableRow
						key={invoice.id}
						invoice={invoice}
						onClick={() => onClick(invoice)}
					/>
				))}
			</tbody>
			<tfoot style={{ borderTop: `1px solid ${theme.colors.gray[7]}` }}>
				<tr>
					<td colSpan={2} />
					<td
						align="right"
						style={{
							fontFamily: theme.other.currencyFont,
							whiteSpace: 'nowrap',
						}}
					>
						BAM
					</td>
					<td
						align="right"
						style={{
							color: theme.colors.dark[9],
							whiteSpace: 'nowrap',
						}}
					>
						{invoiceAndQuote.printAnyPrice(sum)}
					</td>
				</tr>
			</tfoot>
		</Table>
	);
}
