import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	ActionIcon,
	Anchor,
	Breadcrumbs,
	Container,
	Divider,
	Group,
	LoadingOverlay,
	Space,
	Text,
	useMantineTheme,
} from '@mantine/core';
import { useAccountHistory, useAccounts } from '../../hooks/financialAccounts.hooks';
import { useCurrencies } from '../../hooks/currencies.hooks';
import { Amount } from '../../domain';
import { Minus, Pencil, Plus } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import UpsertAccountForm from '../../layouts/financialAccounts/UpsertAccount.form';
import { useSimpleMutation } from '../../queryUtils';
import { useApi } from '../../hooks/api.hooks';
import { depositAmount, setBalance, withdrawAmount } from '../../quickActions/balance.actions';
import makeBamConversions from '../../util/makeBamConversions';
import { useStocks } from '../../hooks/stocks.hooks';

export default function FinancialAccountDetails(): JSX.Element {
	const theme = useMantineTheme();
	const api = useApi();
	const modals = useModals();
	const routeParams = useParams();
	const navigate = useNavigate();
	const deleteAccount = useSimpleMutation(api.finance.accounts.deleteAccount);
	const updateAccount = useSimpleMutation(api.finance.accounts.updateAccount);
	const {
		data: account,
		status: accountStatus,
		isError: isAccountError,
	} = useAccountHistory(routeParams.accountId || '');
	const { data: accountsData = [] } = useAccounts();
	const { data: currencies = [], status: currencyStatus, isError: isCurrencyError } = useCurrencies(new Date());
	const {
		data: stockData,
		status: stockStatus,
		isError: isStockError,
	} = useStocks(account ? [account.currency] : []);

	const goToFinances = () => navigate('..');

	if (isAccountError || isCurrencyError || isStockError) {
		goToFinances();
	}

	if (accountStatus !== 'success' || !account || currencyStatus !== 'success' || stockStatus !== 'success') {
		return <LoadingOverlay visible />;
	}

	const conversions = makeBamConversions(currencies, stockData);

	const openUpdateModal = () => {
		const id = modals.openModal({
			title: 'Update Financial Account',
			children: (
				<UpsertAccountForm
					authorities={accountsData.map((item) => item.group)}
					account={account}
					onClose={() => modals.closeModal(id)}
					onSubmit={(props) => updateAccount({
						...props,
						accountId: account.id,
					})}
					onDelete={() => {
						deleteAccount({ accountId: account.id });
						goToFinances();
					}}
				/>
			),
		});
	};

	const valueRows: { currency: string, value: Amount }[] = [];
	const accountStock = stockData[account.currency];
	if (accountStock) {
		valueRows.push({
			currency: accountStock.currency,
			value: accountStock.close.mul(account.balance).round(2),
		});
	}
	const conversionRate = conversions.get(account.currency);
	if (account.currency !== 'BAM' && conversionRate) {
		valueRows.push({
			currency: 'BAM',
			value: conversionRate.mul(account.balance).round(2),
		});
	}

	const firstValueRow = {
		currency: account.currency,
		value: account.balance,
	};

	return <Container size={650}>
		<Breadcrumbs>
			<Anchor component={Link} to="..">Finances</Anchor>
			<Anchor component={Link} to=".">{account.name}</Anchor>
		</Breadcrumbs>
		<Space h={5} />
		<Divider size="xs" />
		<Space h="sm" />
		<Group direction="row" position="apart" align="flex-start" noWrap>
			<Group direction="column" spacing="xs">
				<Group align="baseline">
					<Text
						size="sm"
						color="dimmed"
						sx={{ minWidth: 60 }}
					>
						Name
					</Text>
					<Text>{account.name}</Text>
				</Group>
				<Group align="baseline">
					<Text
						size="sm"
						color="dimmed"
						sx={{ minWidth: 60 }}
					>
						Authority
					</Text>
					<Text>{account.group}</Text>
				</Group>
				<Group align="baseline">
					<Text
						size="sm"
						color="dimmed"
						sx={{ minWidth: 60 }}
					>
						Currency
					</Text>
					<Text
						sx={{
							fontFamily: theme.other.currencyFont,
						}}
					>
						{account.currency}
					</Text>
				</Group>
			</Group>
			<ActionIcon color="blue" onClick={openUpdateModal} size="sm">
				<Pencil size={16} />
			</ActionIcon>
		</Group>
		<Space h="sm" />
		<Divider size="xs" />
		<Space h="sm" />
		<Group direction="row" position="apart" align="flex-start" noWrap>
			<Group direction="column" spacing={0}>
				<Text
					size="sm"
					color="dimmed"
				>
					Amount
				</Text>
				<Group
					align="baseline"
					spacing="xs"
				>
					<Text
						size="md"
						color="dimmed"
						align="right"
						sx={{
							fontFamily: theme.other.currencyFont,
						}}
					>
						{firstValueRow.currency}
					</Text>
					<Text
						size="xl"
					>
						{firstValueRow.value.toDotNotation(2, !firstValueRow.currency.startsWith('$'))}
					</Text>
				</Group>
				{valueRows.map(({ currency, value }, idx) => (
					<Group
						key={idx}
						align="baseline"
						spacing="xs"
					>
						<Text
							size="xs"
							color="dimmed"
							align="right"
							sx={{
								minWidth: 40,
								fontFamily: theme.other.currencyFont,
							}}
						>
							{currency}
						</Text>
						<Text
							size="sm"
							color="gray"
						>
							{value.toDotNotation(2, !currency.startsWith('$'))}
						</Text>
					</Group>
				))}
			</Group>
			<Group direction="column" spacing="xs" align="flex-end">
				<ActionIcon
					color="blue"
					size="sm"
					onClick={() => setBalance(modals, [account])}
				>
					<Pencil size={16} />
				</ActionIcon>
				<ActionIcon
					color="blue"
					size="sm"
					onClick={() => depositAmount(modals, [account])}
				>
					<Plus size={16} />
				</ActionIcon>
				<ActionIcon
					color="blue"
					size="sm"
					onClick={() => withdrawAmount(modals, [account])}
				>
					<Minus size={16} />
				</ActionIcon>
			</Group>
		</Group>
	</Container>;
};
