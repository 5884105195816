import React from 'react';
import { Currency, FinancialAccount, Stock } from '../../api/types';
import { Table, useMantineTheme } from '@mantine/core';
import { Amount } from '../../domain';
import makeBamConversions from '../../util/makeBamConversions';

type Props = {
	data: FinancialAccount[];
	currencies: Currency[];
	stocks: { [key: string]: Stock };
	onClick: (account: FinancialAccount) => void;
}

export default function AccountsTable({ data, currencies, stocks, onClick }: Props): JSX.Element {
	const theme = useMantineTheme();
	const conversions = makeBamConversions(currencies, stocks);
	const convertedAccounts = data.map((account) => {
		const conversion = conversions.get(account.currency);
		if (!conversion) {
			return {
				account,
				converted: null,
			};
		}
		return {
			account,
			converted: account.balance.mul(conversion).round(2),
		};
	});
	convertedAccounts.sort((a, b) =>
		a.account.group.localeCompare(b.account.group) || a.account.name.localeCompare(b.account.name),
	);
	const sum = convertedAccounts.reduce(({
		amount,
		invalid,
	}, { converted }) => converted ? { amount: amount.add(converted), invalid } : {
		amount,
		invalid: true,
	}, {
		amount: Amount.zero(),
		invalid: false,
	});
	let lastGroup: string | undefined;
	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ width: '99%' }}>Name</th>
					<th />
					<th style={{ textAlign: 'right' }}>Value</th>
					<th style={{ textAlign: 'right' }}>BAM</th>
				</tr>
			</thead>
			<tbody>
				{convertedAccounts.map(({ account, converted }) => {
					const groupChanged = lastGroup !== account.group;
					lastGroup = account.group;
					return (
						<React.Fragment
							key={account.id}
						>
							{groupChanged && (
								<tr>
									<td
										colSpan={4}
										style={{
											fontWeight: 600,
											backgroundColor: theme.colors.gray[1],
											borderRadius: theme.radius.sm,
											padding: '2px 10px',
											fontSize: theme.fontSizes.xs,
										}}
									>
										{account.group}
									</td>
								</tr>
							)}
							<tr
								onClick={() => onClick(account)}
								style={{ cursor: 'pointer' }}
							>
								<td>{account.name}</td>
								<td
									align="right"
									style={{
										fontFamily: theme.other.currencyFont,
										whiteSpace: 'nowrap',
									}}
								>
									{account.currency}
								</td>
								<td align="right" style={{ whiteSpace: 'nowrap' }}>
									{account.balance.toDotNotation(2, !account.currency.startsWith('$'))}
								</td>
								<td align="right" style={{ whiteSpace: 'nowrap' }}>
									{converted ? converted.toDotNotation(2, true) : '?'}
								</td>
							</tr>
						</React.Fragment>
					);
				})}
			</tbody>
			<tfoot style={{ borderTop: `1px solid ${theme.colors.gray[7]}` }}>
				<tr>
					<td colSpan={2} />
					<td
						align="right"
						style={{
							fontFamily: theme.other.currencyFont,
							whiteSpace: 'nowrap',
						}}
					>
						BAM
					</td>
					<td
						align="right"
						style={{
							color: sum.invalid ? theme.colors.red[7] : theme.colors.dark[9],
							whiteSpace: 'nowrap',
						}}
					>
						{sum.amount.toDotNotation(2, true)}{sum.invalid ? '?' : ''}
					</td>
				</tr>
			</tfoot>
		</Table>
	);
}
