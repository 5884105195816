import React from 'react';
import { Group, ScrollArea } from '@mantine/core';
import { NetWorth } from '../../cards';

export default function Overview(): JSX.Element {
	return (
		<ScrollArea sx={{ height: '100%' }}>
			<Group>
				<NetWorth />
			</Group>
		</ScrollArea>
	);
};
