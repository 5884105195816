import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ActionIcon, Anchor, Breadcrumbs, Container, Divider, Group, LoadingOverlay, Space } from '@mantine/core';
import { useClients } from '../../hooks/financialClients.hooks';
import { useCurrencies } from '../../hooks/currencies.hooks';
import { Pencil, Plus } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import UpsertClientForm from '../../layouts/financialClients/UpsertClient.form';
import { useSimpleMutation } from '../../queryUtils';
import { useApi } from '../../hooks/api.hooks';
import ClientServicesTable from '../../layouts/financialClients/ClientServicesTable';
import InsertClientServiceForm from '../../layouts/financialClients/InsertClientService.form';
import { FinancialClientService } from '../../api/types';
import QuickButton from '../../components/QuickButton';
import ViewService from '../../modals/ViewService.modal';
import { FullClientInfo } from '../../layouts/financialClients/FullClientInfo';

export default function ClientDetails(): JSX.Element {
	const api = useApi();
	const modals = useModals();
	const routeParams = useParams();
	const navigate = useNavigate();
	const deleteClient = useSimpleMutation(api.finance.clients.deleteClient);
	const updateClient = useSimpleMutation(api.finance.clients.updateClient);
	const addService = useSimpleMutation(api.finance.clients.addService);
	const {
		data: clients,
		status: clientsStatus,
		isError: isClientsError,
	} = useClients();
	const { data: currencies = [] } = useCurrencies(new Date());
	const client = clients?.find((item) => item.id === routeParams.clientId);

	const goToClients = () => navigate('..');

	if (isClientsError) {
		goToClients();
	}

	if (clients?.length && !client) {
		goToClients();
	}

	if (clientsStatus !== 'success' || !client) {
		return <LoadingOverlay visible />;
	}


	const openUpdateModal = () => {
		const id = modals.openModal({
			title: 'Update Financial Client',
			children: (
				<UpsertClientForm
					currencies={currencies}
					client={client}
					onClose={() => modals.closeModal(id)}
					onSubmit={(props) => updateClient({
						...props,
						clientId: client.id,
					})}
					onDelete={() => {
						deleteClient({ clientId: client.id });
						goToClients();
					}}
				/>
			),
		});
	};

	const openServiceModal = () => {
		const id = modals.openModal({
			title: 'Add Service',
			children: (
				<InsertClientServiceForm
					clientId={client.id}
					onClose={() => modals.closeModal(id)}
					onSubmit={addService}
				/>
			),
		});
	};

	const openViewServiceModal = (service: FinancialClientService) => {
		const id = modals.openModal({
			title: 'Service',
			children: (
				<ViewService
					client={client}
					service={service}
					onClose={() => modals.closeModal(id)}
					onDelete={() => undefined}
				/>
			),
		});
	};

	return <Container size={650}>
		<Breadcrumbs>
			<Anchor component={Link} to="..">Clients</Anchor>
			<Anchor component={Link} to=".">{client.info.name}</Anchor>
		</Breadcrumbs>
		<Space h={5} />
		<Divider size="xs" />
		<Space h="sm" />
		<Group direction="row" position="apart" align="flex-start" noWrap>
			<FullClientInfo clientInfo={client.info} />
			<ActionIcon color="blue" onClick={openUpdateModal} size="sm">
				<Pencil size={16} />
			</ActionIcon>
		</Group>
		<Space h="sm" />
		<Divider size="xs" />
		<Space h="sm" />
		<Group position="right">
			<QuickButton
				icon={Plus}
				onClick={openServiceModal}
			>
				Add Service
			</QuickButton>
		</Group>
		<ClientServicesTable
			data={client.services}
			onClick={openViewServiceModal}
		/>
	</Container>;
};
