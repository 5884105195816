import { Collapse, Group, Space } from '@mantine/core';
import React from 'react';
import { FinancialClient, InvoiceItem, PaymentQuoteItem } from '../../api/types';
import { Plus } from 'tabler-icons-react';
import { Amount } from '../../domain';
import QuickButton from '../../components/QuickButton';
import { ServiceTable } from './ServiceTable';
import { ServiceSum } from './ServiceSum';

type Props = {
	items: { serviceId: string; amount: Amount }[];
	client: FinancialClient | undefined;
	remove: (indices: number | number[]) => void;
	add: () => void;
	exchangeRate?: string;
};

export function ServiceListing({ items, client, remove, add, exchangeRate = '' }: Props) {
	const invalidServiceIndices: number[] = [];
	const tableItems: (InvoiceItem | PaymentQuoteItem)[] = [];
	items.forEach(({ serviceId, amount }, idx) => {
		const service = client?.services.find((service) => service.id === serviceId);
		if (!service) {
			invalidServiceIndices.push(idx);
			return;
		}
		tableItems.push({ service, amount });
	});
	if (invalidServiceIndices.length > 0) {
		remove(invalidServiceIndices);
	}

	const conversionRate = Amount.parseResult(exchangeRate);

	return (
		<Collapse in={!!client}>
			<Collapse in={items.length > 0}>
				<Space h={32} />
				<ServiceTable
					items={tableItems}
					clientInfo={client?.info}
					remove={remove}
				/>
				<Group direction="row" position="right">
					<ServiceSum
						items={tableItems}
						clientInfo={client?.info}
						remove={remove}
						exchangeRate={conversionRate.ok ? conversionRate.value : undefined}
					/>
				</Group>
			</Collapse>
			<Space h={8} />
			<Group position="right">
				<QuickButton
					icon={Plus}
					onClick={add}
				>
					Add Item
				</QuickButton>
			</Group>
			<Space h={8} />
		</Collapse>
	);
}
