import { asString, isFromAs, isString, result, Result, structure } from '@factoryfour/type-check';

const isErrMessage = isFromAs(structure({
	message: asString,
}));

const parseBigInt = (src: string): Result<bigint> => {
	try {
		return result.ok(BigInt(src));
	} catch (err) {
		if (isErrMessage(err)) {
			return result.errMsg(err.message);
		}
		if (isString(err)) {
			return result.errMsg(err);
		}
		return result.errMsg(JSON.stringify(err));
	}
};

export default class Amount {
	unsignedNom: bigint;

	unsignedDenom: bigint;

	negative: boolean;

	static zero(): Amount {
		return new Amount(BigInt(0), BigInt(1));
	}

	static parse(src: string, noRatio = false): Amount {
		return result.unwrap(Amount.parseResult(src, noRatio));
	}

	static parseResult(src: string, noRatio = false): Result<Amount> {
		let srcNoSpaces = src.replaceAll(/\s+/g, '');
		let [nom, denom, ...rest] = srcNoSpaces.split('/');
		if (rest.length !== 0) {
			return result.errMsg('More than one "/" in amount string');
		}
		let negative = false;
		if (nom && nom.startsWith('-')) {
			nom = nom.substring(1);
			negative = !negative;
		}
		if (denom && denom.startsWith('-')) {
			denom = denom.substring(1);
			negative = !negative;
		}
		if (denom) {
			if (noRatio) {
				return result.errMsg('Ratio representation is not allowed');
			}
			const nomResult = parseBigInt(nom);
			if (result.isErr(nomResult)) {
				return nomResult;
			}
			const denomResult = parseBigInt(denom);
			if (result.isErr(denomResult)) {
				return denomResult;
			}
			if (denomResult.value === BigInt(0)) {
				return result.errMsg('Fraction denominator cannot be 0');
			}
			return result.ok(new Amount(
				nomResult.value,
				negative ? -denomResult.value : denomResult.value,
			));
		}
		const [whole, fraction = '', ...rest2] = nom.split('.');
		if (rest2.length !== 0) {
			return result.errMsg('More than one "." in amount string');
		}
		let power = BigInt(1);
		for (let idx = 0; idx < fraction.length; idx += 1) {
			power *= BigInt(10);
		}

		const wholeResult = parseBigInt(whole || '0');
		if (result.isErr(wholeResult)) {
			return wholeResult;
		}
		const fractionResult = parseBigInt(fraction || '0');
		if (result.isErr(fractionResult)) {
			return fractionResult;
		}
		return result.ok(new Amount(
			wholeResult.value * power + fractionResult.value,
			negative ? -power : power,
		));
	}

	constructor(nom: bigint, denom: bigint) {
		this.unsignedNom = nom;
		this.unsignedDenom = denom;
		this.negative = false;
		this.normalize();
	}

	private normalize = (): void => {
		if (this.unsignedDenom < BigInt(0)) {
			this.unsignedDenom = -this.unsignedDenom;
			this.negative = !this.negative;
		}
		if (this.unsignedNom < BigInt(0)) {
			this.unsignedNom = -this.unsignedNom;
			this.negative = !this.negative;
		}
		let nextGcd: bigint;
		let gcd: bigint;
		if (this.unsignedNom < this.unsignedDenom) {
			nextGcd = this.unsignedNom;
			gcd = this.unsignedDenom;
		} else {
			gcd = this.unsignedNom;
			nextGcd = this.unsignedDenom;
		}
		while (nextGcd > BigInt(0)) {
			const oldGcd = gcd;
			gcd = nextGcd;
			nextGcd = oldGcd % gcd;
		}
		if (gcd > BigInt(1)) {
			this.unsignedNom /= gcd;
			this.unsignedDenom /= gcd;
		}
	};

	neg = (): Amount => new Amount(-this.unsignedNom, this.unsignedDenom);

	signedNom = (): bigint => this.negative ? -this.unsignedNom : this.unsignedNom;

	add = (amount: Amount): Amount => new Amount(
		this.signedNom() * amount.unsignedDenom + amount.signedNom() * this.unsignedDenom,
		this.unsignedDenom * amount.unsignedDenom,
	);

	sub = (amount: Amount): Amount => new Amount(
		this.signedNom() * amount.unsignedDenom - amount.signedNom() * this.unsignedDenom,
		this.unsignedDenom * amount.unsignedDenom,
	);

	mul = (amount: Amount): Amount => new Amount(
		this.signedNom() * amount.signedNom(),
		this.unsignedDenom * amount.unsignedDenom,
	);

	div = (amount: Amount): Amount => new Amount(
		this.signedNom() * amount.unsignedDenom,
		this.unsignedDenom * amount.signedNom(),
	);

	private floorNoDecimals = (): Amount => {
		const div = this.unsignedNom / this.unsignedDenom;
		if (!this.negative) {
			return new Amount(div, BigInt(1));
		}
		const mod = this.unsignedNom % this.unsignedDenom;
		if (mod === BigInt(0)) {
			return new Amount(div, BigInt(-1));
		}
		return new Amount(div + BigInt(1), BigInt(-1));
	};

	floor = (decimalDigits: number): Amount => {
		let power = BigInt(1);
		for (let idx = 0; idx < decimalDigits; idx += 1) {
			power *= BigInt(10);
		}
		const scale = new Amount(power, BigInt(1));
		return this.mul(scale).floorNoDecimals().div(scale);
	};

	private ceilNoDecimals = (): Amount => {
		const div = this.unsignedNom / this.unsignedDenom;
		if (this.negative) {
			return new Amount(div, BigInt(-1));
		}
		const mod = this.unsignedNom % this.unsignedDenom;
		if (mod === BigInt(0)) {
			return new Amount(div, BigInt(1));
		}
		return new Amount(div + BigInt(1), BigInt(1));
	};

	ceil = (decimalDigits: number): Amount => {
		let power = BigInt(1);
		for (let idx = 0; idx < decimalDigits; idx += 1) {
			power *= BigInt(10);
		}
		const scale = new Amount(power, BigInt(1));
		return this.mul(scale).ceilNoDecimals().div(scale);
	};

	roundNoDecimals = (): Amount => {
		return this.add(new Amount(BigInt(1), BigInt(2))).floorNoDecimals();
	};

	round = (decimalDigits: number): Amount => {
		let power = BigInt(1);
		for (let idx = 0; idx < decimalDigits; idx += 1) {
			power *= BigInt(10);
		}
		const scale = new Amount(power, BigInt(1));
		return this.mul(scale).roundNoDecimals().div(scale);
	};

	toRatio = (): string => `${this.negative ? '-' : ''}${this.unsignedNom}/${this.unsignedDenom}`;

	toDotNotation = (decimalDigits: number, force = false, dot = '.'): string => {
		const whole = this.unsignedNom / this.unsignedDenom;
		let rest = this.unsignedNom % this.unsignedDenom;
		const sign = this.negative ? '-' : '';
		const digits = [];
		for (let idx = 0; idx < decimalDigits && (force || rest !== BigInt(0)); idx += 1) {
			rest *= BigInt(10);
			digits.push(rest / this.unsignedDenom);
			rest %= this.unsignedDenom;
		}
		if (!force) {
			while (digits[digits.length - 1] === BigInt(0)) {
				digits.pop();
			}
		}
		const wholeString = `${whole}`;
		const wholeStringParts: string[] = [];
		for (let idx = wholeString.length; idx > 0; idx -= 3) {
			wholeStringParts.push(wholeString.substring(Math.max(idx - 3, 0), idx));
		}
		wholeStringParts.reverse();
		if (digits.length === 0) {
			return `${sign}${wholeStringParts.join(' ')}`;
		}
		return `${sign}${wholeStringParts.join(' ')}${dot}${digits.join('')}`;
	};
}
