import { Divider, Group, Space, Text } from '@mantine/core';
import React from 'react';
import { CompanyInfo } from '../../api/types';
import { useAuth0 } from '@auth0/auth0-react';
import OnlyPrint from '../../components/OnlyPrint';
import { useIntl } from 'react-intl';

type Props = {
	info: CompanyInfo;
	hideInWeb?: boolean;
};

function TextItem({ items }: { items: string[] }) {
	return (
		<Text
			align="center"
			sx={{ width: '100%' }}
			weight={700}
		>
			{items.filter((item) => item).join(' • ')}
		</Text>
	);
}

export default function PrintPaymentInfo({ info }: Props) {
	const { user } = useAuth0();
	const intl = useIntl();
	const { bank, iban, swift } = intl.locale === 'bs' ? info.paymentBa : info.paymentEn;
	const invoiceSignature = intl.formatMessage({
		id: 'RgVTSF',
		description: 'Written above the line for signing letters',
		defaultMessage: 'Signature',
	});
	const localBankAccountInfo = intl.formatMessage({
		id: 'zaMwEV',
		description: 'Message for providing local bank account',
		defaultMessage: ' ',
	}, {
		accountNumber: info.paymentBa.localBankAccount,
	});
	return (
		<OnlyPrint>
			<Group
				direction="column"
				sx={{
					width: '100%',
					position: 'absolute',
					bottom: 0,
				}}
				spacing={0}
			>
				<Group direction="row" position="right" sx={{ width: '100%' }}>
					<Group direction="column" position="right" spacing={0}>
						<Text size="sm">
							{invoiceSignature}
						</Text>
						<Space h={100} />
						<Divider
							sx={{ width: 180 }}
							color="dark"
						/>
						<Text size="sm">
							{user?.name || ''}
						</Text>
					</Group>
				</Group>
				<Space h="sm" />
				<TextItem
					items={[localBankAccountInfo.trim(), bank]}
				/>
				<TextItem
					items={[iban, swift]}
				/>
				<Space h="md" />
			</Group>
		</OnlyPrint>
	);
}
