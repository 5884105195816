import {
	BalanceAt,
	CompanyInfo,
	Currency,
	FinancialAccount,
	FinancialAccountHistory,
	FinancialClient,
	FinancialClientInfo,
	FinancialClientService,
	Invoice,
	InvoiceItem,
	PaymentQuote,
	PaymentQuoteItem,
	Stock,
} from './types';
import { Amount } from '../domain';

export type RawFinancialAccount = {
	id: string;
	currency: string;
	name: string;
	group: string;
	balance: string;
};

export type RawFinancialAccountHistory = RawFinancialAccount & {
	history: RawBalanceAt[];
};

export type RawBalanceAt = {
	value: string;
	at: number;
}

export const toBalanceAt = ({ value, at }: RawBalanceAt): BalanceAt => ({
	value: Amount.parse(value),
	at: new Date(at),
});

export const toFinancialAccount = ({
	id,
	currency,
	name,
	group,
	balance,
}: RawFinancialAccount): FinancialAccount => ({
	id,
	currency,
	name,
	group,
	balance: Amount.parse(balance),
});

export const toFinancialAccountHistory = ({
	id,
	currency,
	name,
	group,
	balance,
	history,
}: RawFinancialAccountHistory): FinancialAccountHistory => ({
	id,
	currency,
	name,
	group,
	balance: Amount.parse(balance),
	history: history.map(toBalanceAt),
});

export type RawFinancialClientInfo = {
	name: string;
	currency: string;
	businessId: string;
	taxId: string;
	address: string;
	international: boolean;
};

export const toFinancialClientInfo = ({
	name,
	currency,
	businessId,
	taxId,
	address,
	international,
}: RawFinancialClientInfo): FinancialClientInfo => ({
	name,
	currency,
	businessId,
	taxId,
	address,
	international,
});

export type RawFinancialClientService = {
	id: string;
	nameEn: string;
	nameBa: string;
	unit: string;
	unitPrice: string;
	retired: boolean;
};

export const toFinancialClientService = ({
	id,
	nameEn,
	nameBa,
	unit,
	unitPrice,
	retired,
}: RawFinancialClientService): FinancialClientService => ({
	id,
	nameEn,
	nameBa,
	unit,
	unitPrice: Amount.parse(unitPrice),
	retired,
});

export type RawFinancialClient = {
	id: string;
	info?: RawFinancialClientInfo;
	services?: RawFinancialClientService[];
};

export const toFinancialClient = ({
	id,
	info,
	services,
}: RawFinancialClient): FinancialClient => {
	if (!info) {
		throw new Error('Missing field in financial client');
	}
	return {
		id,
		info,
		services: (services || []).map(toFinancialClientService),
	};
};

export type RawInvoiceItem = {
	amount: string;
	service?: RawFinancialClientService;
};

export const toInvoiceItem = ({
	amount,
	service,
}: RawInvoiceItem): InvoiceItem => {
	if (!service) {
		throw new Error('Missing field in invoice item');
	}
	return {
		amount: Amount.parse(amount),
		service: toFinancialClientService(service),
	};
};

export type RawInvoice = {
	id: string;
	issuedAt: string;
	invoiceNumber: string;
	fiscalYear: string;
	billNumber: string;
	exchangeRate: string;
	comment: string;
	client?: { id: string };
	clientInfo?: RawFinancialClientInfo;
	items?: RawInvoiceItem[];
};

export const toInvoice = ({
	id,
	issuedAt,
	invoiceNumber,
	fiscalYear,
	billNumber,
	exchangeRate,
	comment,
	client,
	clientInfo,
	items,
}: RawInvoice): Invoice => {
	if (!client || !clientInfo) {
		throw new Error('Missing field in invoice');
	}
	return {
		id,
		issuedAt: new Date(issuedAt),
		invoiceNumber,
		fiscalYear,
		billNumber,
		exchangeRate: Amount.parse(exchangeRate),
		comment,
		clientId: client.id,
		clientInfo: toFinancialClientInfo(clientInfo),
		items: (items || []).map(toInvoiceItem),
	};
};

export type RawPaymentQuoteItem = {
	amount: string;
	service?: RawFinancialClientService;
};

export const toPaymentQuoteItem = ({
	amount,
	service,
}: RawPaymentQuoteItem): PaymentQuoteItem => {
	if (!service) {
		throw new Error('Missing field in invoice item');
	}
	return {
		amount: Amount.parse(amount),
		service: toFinancialClientService(service),
	};
};

export type RawPaymentQuote = {
	id: string;
	issuedAt: string;
	comment: string;
	client?: { id: string };
	clientInfo?: RawFinancialClientInfo;
	items?: RawPaymentQuoteItem[];
};

export const toPaymentQuote = ({
	id,
	issuedAt,
	comment,
	client,
	clientInfo,
	items,
}: RawPaymentQuote): PaymentQuote => {
	if (!client || !clientInfo) {
		throw new Error('Missing field in invoice');
	}
	return {
		id,
		issuedAt: new Date(issuedAt),
		comment,
		clientId: client.id,
		clientInfo: toFinancialClientInfo(clientInfo),
		items: (items || []).map(toPaymentQuoteItem),
	};
};

export type RawCurrency = {
	country: string;
	code: string;
	units: string;
	buy: string;
	middle: string;
	sell: string;
};

export const toCurrency = ({
	country,
	code,
	units,
	buy,
	middle,
	sell,
}: RawCurrency): Currency => ({
	country,
	code,
	units: Amount.parse(units),
	buy: Amount.parse(buy),
	middle: Amount.parse(middle),
	sell: Amount.parse(sell),
});

export type RawStock = {
	name: string;
	currency: string;
	date: string;
	close: string;
};

export const toStock = ({
	name,
	currency,
	date,
	close,
}: RawStock): Stock => ({
	name,
	currency,
	date: new Date(date),
	close: Amount.parse(close),
});

export type RawCompanyInfoV1 = {
	name: string;
	nameBrand: string;
	nameRest: string;
	addressBa: string;
	addressEn: string;
	phoneNumber: string;
	email: string;
	website: string;
	governmentId: string;
	taxId: string;
	paymentBa: {
		bank: string;
		localBankAccount: string;
		iban: string;
		swift: string;
	};
	paymentEn: {
		bank: string;
		iban: string;
		swift: string;
	};
};

export const toCompanyInfoFromV1 = (data: RawCompanyInfoV1): CompanyInfo => data;
