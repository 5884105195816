import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Group, LoadingOverlay, ScrollArea } from '@mantine/core';
import { useClients } from '../../hooks/financialClients.hooks';
import ClientsTable from '../../layouts/financialClients/ClientsTable';
import { Plus } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';
import UpsertClientForm from '../../layouts/financialClients/UpsertClient.form';
import { useModals } from '@mantine/modals';
import { useApi } from '../../hooks/api.hooks';
import { useSimpleMutation } from '../../queryUtils';
import { useCurrencies } from '../../hooks/currencies.hooks';

export default function Clients(): JSX.Element {
	const api = useApi();
	const modals = useModals();
	const addClient = useSimpleMutation(api.finance.clients.addClient);
	const {
		data: clientsData = [],
		status: clientsStatus,
	} = useClients();
	const { data: currenciesData = [] } = useCurrencies(new Date());
	const navigate = useNavigate();

	if (clientsStatus !== 'success') {
		return <LoadingOverlay visible />;
	}

	const openAddModal = () => {
		const id = modals.openModal({
			title: 'Add Financial Client',
			children: (
				<UpsertClientForm
					onClose={() => modals.closeModal(id)}
					onSubmit={addClient}
					currencies={currenciesData}
				/>
			),
		});
	};

	return (
		<ScrollArea sx={{ height: '100%' }}>
			<Container>
				<Group position="right">
					<QuickButton
						icon={Plus}
						onClick={openAddModal}
					>
						Add Client
					</QuickButton>
				</Group>
				<ClientsTable
					data={clientsData}
					onClick={(client) => navigate(client.id)}
				/>
			</Container>
		</ScrollArea>
	);
};
