import React from 'react';
import { FinancialClient, FinancialClientService } from '../api/types';
import { useSimpleMutation } from '../queryUtils';
import { useApi } from '../hooks/api.hooks';
import { Box, Button, Group, Text } from '@mantine/core';
import QuickButton from '../components/QuickButton';
import { Trash } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { printServiceUnitPrice } from '../domain';

type Props = {
	client: FinancialClient;
	service: FinancialClientService;
	onDelete: () => void;
	onClose: () => void;
};

export default function ViewService({
	client,
	service,
	onDelete,
	onClose,
}: Props) {
	const modals = useModals();
	const api = useApi();
	const retireService = useSimpleMutation(api.finance.clients.retireService);

	const openDeleteModal = () => modals.openConfirmModal({
		title: 'Remove Service',
		children: (
			<Text>{`Are you sure you want to remove service "${service.nameEn}"?`}</Text>
		),
		labels: { confirm: 'Delete', cancel: 'Cancel' },
		confirmProps: {
			color: 'red',
			uppercase: true,
		},
		cancelProps: {
			color: 'gray',
			variant: 'subtle',
			uppercase: true,
		},
		onConfirm: () => {
			retireService({ clientId: client.id, serviceId: service.id });
			onDelete();
			onClose();
		},
	});

	return (
		<Box>
			<Group direction="column" spacing={2}>
				<Group align="baseline">
					<Text
						sx={{ minWidth: 60 }}
						size="sm"
						color="dimmed"
					>
						Name
					</Text>
					<Text>{service.nameEn}</Text>
				</Group>
				<Group align="baseline">
					<Text
						sx={{ minWidth: 60 }}
						size="sm"
						color="dimmed"
					>
					</Text>
					<Text>{service.nameBa}</Text>
				</Group>
				<Group align="baseline">
					<Text
						sx={{ minWidth: 60 }}
						size="sm"
						color="dimmed"
					>
						Unit
					</Text>
					<Text>{service.unit}</Text>
				</Group>
				<Group align="baseline">
					<Text
						sx={{ minWidth: 60 }}
						size="sm"
						color="dimmed"
					>
						Unit Price
					</Text>
					<Text>{printServiceUnitPrice(service.unitPrice)}</Text>
				</Group>
			</Group>
			<Group position="apart" mt="md">
				<QuickButton icon={Trash} color="red" onClick={openDeleteModal}>
					Remove
				</QuickButton>
				<Button uppercase variant="subtle" color="gray" onClick={onClose}>Close</Button>
			</Group>
		</Box>
	);
}
