import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
	ActionIcon,
	Anchor,
	Box,
	Breadcrumbs,
	Container,
	Group,
	LoadingOverlay,
	SegmentedControl,
	Text,
} from '@mantine/core';
import { useQuotes } from '../../hooks/payments.hooks';
import { Trash } from 'tabler-icons-react';
import { useModals } from '@mantine/modals';
import { useSimpleMutation } from '../../queryUtils';
import { useApi } from '../../hooks/api.hooks';
import NoPrint from '../../components/NoPrint';
import PaymentDetails from '../../layouts/payments/PaymentDetails';
import { IntlProvider } from 'react-intl';
import localeMessages from '../../localeMessages';
import { useCompanyInfo } from '../../hooks/companyInfo.hooks';

export default function QuoteDetails(): JSX.Element {
	const api = useApi();
	const modals = useModals();
	const routeParams = useParams();
	const navigate = useNavigate();
	const [language, setLanguage] = useState('bs');
	const deleteQuote = useSimpleMutation(api.finance.quotes.deleteQuote);
	const {
		data: quotes,
		status: quotesStatus,
		isError: isQuotesError,
	} = useQuotes();
	const {
		data: companyInfo,
		status: companyInfoStatus,
		isError: isCompanyInfoError,
	} = useCompanyInfo();
	const quote = quotes?.find((item) => item.id === routeParams.quoteId);

	const goToQuotes = () => navigate('..');

	if (isQuotesError || isCompanyInfoError) {
		goToQuotes();
	}

	if (quotes?.length && !quote) {
		goToQuotes();
	}

	if (quotesStatus !== 'success' || companyInfoStatus !== 'success' || !quote || !companyInfo) {
		return <LoadingOverlay visible />;
	}

	const openDeleteModal = () => modals.openConfirmModal({
		title: 'Remove Client',
		children: (
			<Text>{`Are you sure you want to remove this quote?`}</Text>
		),
		labels: { confirm: 'Delete', cancel: 'Cancel' },
		confirmProps: {
			color: 'red',
			uppercase: true,
		},
		cancelProps: {
			color: 'gray',
			variant: 'subtle',
			uppercase: true,
		},
		onConfirm: () => {
			deleteQuote({ quoteId: quote.id });
			goToQuotes();
		},
	});

	return <Container size={650} sx={{ height: '100%' }}>
		<Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
			<NoPrint>
				<Group direction="column" grow>
					<Group position="apart">
						<Breadcrumbs>
							<Anchor component={Link} to="..">Quotes</Anchor>
							<Anchor component={Link} to=".">Quote</Anchor>
						</Breadcrumbs>
						<SegmentedControl
							value={language}
							onChange={setLanguage}
							data={[
								{
									label: 'English',
									value: 'en',
								},
								{
									label: 'Bosanski',
									value: 'bs',
								},
							]}
							color="blue"
							size="xs"
						/>
					</Group>
				</Group>
			</NoPrint>
			<IntlProvider
				locale={language}
				messages={localeMessages[language]}
			>
				<PaymentDetails
					type="quote"
					value={quote}
					companyInfo={companyInfo}
				/>
			</IntlProvider>
			<NoPrint>
				<Group position="right" sx={{ width: '100%' }}>
					<ActionIcon color="red" onClick={openDeleteModal} variant="outline" size="lg">
						<Trash size={14} />
					</ActionIcon>
				</Group>
			</NoPrint>
		</Box>
	</Container>;
};
