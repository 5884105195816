import { MediaQuery } from '@mantine/core';
import React from 'react';

export default function OnlyPrint({ children, disabled }: React.PropsWithChildren<{ disabled?: boolean }>) {
	return (
		<MediaQuery
			query="not print"
			styles={disabled ? {} : { display: 'none' }}
		>
			{children}
		</MediaQuery>
	);
}
