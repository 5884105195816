import React from 'react';
import { Invoice } from '../../api/types';
import { invoiceAndQuote } from '../../domain';

export function InvoicesTableRow({ invoice, onClick }: { invoice: Invoice, onClick: () => void }) {
	const { convertedWithVat } = invoiceAndQuote.overallPrice(invoice.items, invoice.exchangeRate, invoice.clientInfo.international);
	return (
		<tr
			key={invoice.id}
			onClick={onClick}
			style={{ cursor: 'pointer' }}
		>
			<td>{invoice.clientInfo.name}</td>
			<td
				align="right"
				style={{ whiteSpace: 'nowrap' }}
			>
				{`${invoice.invoiceNumber}/${invoice.fiscalYear}`}
			</td>
			<td
				align="right"
				style={{ whiteSpace: 'nowrap' }}
			>
				{invoice.issuedAt.toLocaleDateString()}
			</td>
			<td
				align="right"
				style={{ whiteSpace: 'nowrap' }}
			>
				{invoiceAndQuote.printAnyPrice(convertedWithVat)}
			</td>
		</tr>
	);
}
