import React from 'react';

export default function MultilineText({ text }: { text: string }) {
	return (
		<>
			{
				text.split('\n').map((row, idx) => (
					<React.Fragment key={idx}>
						{row}<br />
					</React.Fragment>
				))
			}
		</>
	);
}
