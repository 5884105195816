import { Table, useMantineTheme } from '@mantine/core';
import React from 'react';
import { FinancialClientInfo, InvoiceItem, PaymentQuoteItem } from '../../api/types';
import { ServiceTableRow } from './ServiceTableRow';
import { useIntl } from 'react-intl';
import currencyTranslations from './util/currencyTranslations';

type Props = {
	items: (InvoiceItem | PaymentQuoteItem)[];
	clientInfo: FinancialClientInfo | undefined;
	remove?: (indices: number | number[]) => void;
};

export function ServiceTable({
	items,
	clientInfo,
	remove,
}: Props) {
	const intl = useIntl();
	const theme = useMantineTheme();

	const currencies = currencyTranslations[intl.locale] || {};
	const currency = clientInfo?.currency ? currencies[clientInfo?.currency] || clientInfo.currency : '';

	const idLabel = intl.formatMessage({
		id: 'VOEdai',
		defaultMessage: 'ID',
		description: 'Services table, column ID',
	});

	const descriptionLabel = intl.formatMessage({
		id: 'Ngb/Yg',
		defaultMessage: 'Description',
		description: 'Services table, column service description',
	});

	const unitLabel = intl.formatMessage({
		id: 'gHM40h',
		defaultMessage: 'Unit',
		description: 'Services table, column unit',
	});

	const amountLabel = intl.formatMessage({
		id: '8kfFWe',
		defaultMessage: 'Amount',
		description: 'Services table, column amount',
	});

	const unitPriceLabel = intl.formatMessage({
		id: 'JUpNvZ',
		defaultMessage: 'Price ({currency})',
		description: 'Services table, column unit price',
	}, { currency });

	const overallPriceLabel = intl.formatMessage({
		id: 'CzaU3U',
		defaultMessage: 'Overall ({currency})',
		description: 'Services table, column overall price',
	}, { currency });

	return (
		<Table highlightOnHover>
			<thead>
				<tr>
					<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{idLabel}</th>
					<th style={{ width: '99%', whiteSpace: 'nowrap' }}>{descriptionLabel}</th>
					<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{unitLabel}</th>
					<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{unitPriceLabel}</th>
					<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{amountLabel}</th>
					<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }}>{overallPriceLabel}</th>
					{remove && (<th style={{ textAlign: 'right', whiteSpace: 'nowrap' }} />)}
				</tr>
			</thead>
			<tbody>
				{items.map((item, idx) => (
					<ServiceTableRow
						key={idx}
						idx={idx}
						item={item}
						remove={remove ? () => remove(idx) : undefined}
					/>
				))}
			</tbody>
			<tfoot style={{ borderTop: `1px solid ${theme.colors.gray[7]}` }} />
		</Table>
	);
}
