import { DetailRow } from '../../components/DetailRow';
import { Group } from '@mantine/core';
import React from 'react';
import { Invoice, PaymentQuote } from '../../api/types';
import { useIntl } from 'react-intl';

type Props = {
	type: 'invoice';
	value: Invoice;
} | {
	type: 'quote';
	value: PaymentQuote;
}

export default function DateAndBillNumber({ type, value }: Props) {
	const intl = useIntl();
	const issueDateLabel = {
		invoice: intl.formatMessage({
			id: 'bUBPg0',
			defaultMessage: 'Issue date',
			description: 'Label in invoice for issue date',
		}),
		quote: intl.formatMessage({
			id: 'G4XXWU',
			defaultMessage: 'Issue date',
			description: 'Label in quote for issue date',
		}),
	};
	const billIdLabel = intl.formatMessage({
		id: 'qlgbqy',
		defaultMessage: 'Fiscal receipt ID',
		description: 'Label in invoice for bill ID',
	});
	return (
		<Group direction="column" spacing={0}>
			<DetailRow width={105} label={issueDateLabel[type]}>
				{value.issuedAt.toLocaleDateString(intl.locale === 'bs' ? 'de' : 'en')}
			</DetailRow>
			{type === 'invoice' && (
				<DetailRow width={105} label={billIdLabel}>
					{value.billNumber}
				</DetailRow>
			)}
		</Group>
	);
}
