import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Group, LoadingOverlay } from '@mantine/core';
import { Plus } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';
import { useQuotes } from '../../hooks/payments.hooks';
import QuotesTable from '../../layouts/payments/QuotesTable';
import CreateQuoteForm from '../../layouts/payments/CreateQuote.form';
import { useApi } from '../../hooks/api.hooks';
import { useModals } from '@mantine/modals';
import { useSimpleMutation } from '../../queryUtils';

export default function Quotes(): JSX.Element {
	const api = useApi();
	const modals = useModals();
	const {
		data: quotesData = [],
		status: quotesStatus,
	} = useQuotes();
	const navigate = useNavigate();
	const addQuote = useSimpleMutation(api.finance.quotes.addQuote);

	const openAddModal = () => {
		const id = modals.openModal({
			title: 'Add Quote',
			size: 'full',
			children: (
				<CreateQuoteForm
					onClose={() => modals.closeModal(id)}
					onSubmit={addQuote}
				/>
			),
		});
	};

	if (quotesStatus !== 'success') {
		return <LoadingOverlay visible />;
	}

	return (
		<>
			<Group
				position="right"
				style={{
					minHeight: '28px',
				}}
			>
				<QuickButton
					icon={Plus}
					onClick={openAddModal}
				>
					Create Quote
				</QuickButton>
			</Group>
			<QuotesTable
				data={quotesData}
				onClick={(quote) => navigate(quote.id)}
			/>
		</>
	);
};
