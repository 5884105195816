import { Box, Navbar, ScrollArea, useMantineTheme } from '@mantine/core';
import NavigationLink from './components/NavigationLink';
import { Cash, FileInvoice, Notes, Users } from 'tabler-icons-react';
import CurrentUserMenu from './components/CurrentUserMenu';
import React from 'react';
import NoPrint from './components/NoPrint';

type Props = {
	hidden: boolean;
	hide: () => void;
};

export default function Navigation({ hidden, hide }: Props) {
	const theme = useMantineTheme();

	return <NoPrint>
		<Navbar
			width={{ sm: 300, lg: 400 }}
			p="sm"
			hiddenBreakpoint="sm"
			hidden={hidden}
		>
			<Navbar.Section
				grow
				component={ScrollArea}
				mx="-xs"
				px="xs"
			>
				<NavigationLink
					to="/overview"
					label="Overview"
					icon={<Notes size={14} />}
					color="blue"
					onClick={hide}
				/>
				<NavigationLink
					to="/accounts"
					label="Accounts"
					icon={<Cash size={14} />}
					color="blue"
					onClick={hide}
				/>
				<NavigationLink
					to="/clients"
					label="Clients"
					icon={<Users size={14} />}
					color="blue"
					onClick={hide}
				/>
				<NavigationLink
					to="/payments"
					label="Payments"
					icon={<FileInvoice size={14} />}
					color="blue"
					onClick={hide}
				/>
			</Navbar.Section>
			<Navbar.Section>
				<Box
					sx={{
						paddingTop: theme.spacing.sm,
						borderTop: `1px solid ${theme.colors.gray[2]}`,
					}}
				>
					<CurrentUserMenu />
				</Box>
			</Navbar.Section>
		</Navbar>
	</NoPrint>;
}
