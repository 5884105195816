import React from 'react';
import SetBalance from '../modals/SetBalance.modal';
import { ModalsContextProps } from '@mantine/modals/lib/context';
import { FinancialAccount } from '../api/types';
import { Amount } from '../domain';

export function setBalance(
	modals: ModalsContextProps,
	target: FinancialAccount[],
	value?: Amount,
) {
	const id = modals.openModal({
		title: 'Set Balance',
		children: (
			<SetBalance
				target={target}
				value={value}
				operator="set"
				submitLabel="Set"
				onClose={() => modals.closeModal(id)}
			/>
		),
	});
}

export function depositAmount(
	modals: ModalsContextProps,
	target: FinancialAccount[],
	value?: Amount,
) {
	const id = modals.openModal({
		title: 'Deposit Amount',
		children: (
			<SetBalance
				target={target}
				value={value}
				operator="deposit"
				submitLabel="Deposit"
				onClose={() => modals.closeModal(id)}
			/>
		),
	});
}

export function withdrawAmount(
	modals: ModalsContextProps,
	target: FinancialAccount[],
	value?: Amount,
) {
	const id = modals.openModal({
		title: 'Withdraw Amount',
		children: (
			<SetBalance
				target={target}
				value={value}
				operator="withdraw"
				submitLabel="Withdraw"
				onClose={() => modals.closeModal(id)}
			/>
		),
	});
}
