import { Button, Collapse, Group, Radio, RadioGroup, Select, Text, Textarea, TextInput } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { Currency, FinancialClient } from '../../api/types';
import { useModals } from '@mantine/modals';
import { Trash } from 'tabler-icons-react';
import QuickButton from '../../components/QuickButton';

type Props = {
	client?: FinancialClient;
	currencies: Currency[];
	onClose: () => void;
	onSubmit: (props: {
		name: string;
		currency: string;
		businessId: string;
		taxId: string;
		address: string;
		international: boolean;
	}) => void;
	onDelete?: () => void;
}

export default function UpsertClient({
	client,
	currencies,
	onClose,
	onSubmit,
	onDelete,
}: Props): JSX.Element {
	const modals = useModals();
	const sortedCurrencies = currencies.slice();
	sortedCurrencies.sort((a, b) => a.code.localeCompare(b.code));
	let initialTaxLocation;
	switch (client?.info.international) {
		case true:
			initialTaxLocation = 'international';
			break;
		case false:
			initialTaxLocation = 'local';
			break;
		default:
			initialTaxLocation = '';
			break;
	}
	const form = useForm({
		initialValues: {
			name: client?.info.name || '',
			currency: client?.info.currency || '',
			businessId: client?.info.businessId || '',
			taxId: client?.info.taxId || '',
			address: client?.info.address || '',
			taxLocation: initialTaxLocation,
		},
		validate: {
			name: (value) => value.trim() !== '' ? null : 'Required',
			currency: (value) => currencies.find((item) => item.code === value) ? null : 'Required',
			businessId: (value) => value.length === 14 ? null : 'Needs to be 14 digits long',
			taxId: (value) => (value.length === 13 || value.length === 0) ? null : 'Needs to be empty or 13 digits long',
			address: (value) => value.trim() !== '' ? null : 'Required',
			taxLocation: (value) => value !== '' ? null : 'Required',
		},
	});

	const openDeleteModal = () => modals.openConfirmModal({
		title: 'Remove Client',
		children: (
			<Text>{`Are you sure you want to remove client "${client?.info.name}"?`}</Text>
		),
		labels: { confirm: 'Delete', cancel: 'Cancel' },
		confirmProps: {
			color: 'red',
			uppercase: true,
		},
		cancelProps: {
			color: 'gray',
			variant: 'subtle',
			uppercase: true,
		},
		onConfirm: () => {
			onDelete && onDelete();
			onClose();
		},
	});

	return (
		<form onSubmit={form.onSubmit((values) => {
			const international = values.taxLocation === 'international';
			let props = {
				name: values.name.trim(),
				currency: international ? values.currency.trim() : 'BAM',
				businessId: values.businessId.trim(),
				taxId: values.taxId.trim(),
				address: values.address.trim(),
				international: international,
			};
			onSubmit(props);
			onClose();
		})}>
			<TextInput
				required
				label="Name"
				placeholder="eg. Big Company"
				{...form.getInputProps('name')}
			/>

			<RadioGroup
				required
				description="Where is the company located? For tax purposes."
				label="Location"
				{...form.getInputProps('taxLocation')}
			>
				<Radio value="local" label="Local" />
				<Radio value="international" label="International" />
			</RadioGroup>

			<Collapse in={form.values.taxLocation === 'international'}>
				<Select
					required
					label="Currency"
					placeholder="eg. BAM, USD"
					data={sortedCurrencies.map((currency) => ({
						value: currency.code,
						label: `${currency.code} (${currency.country})`,
					}))}
					clearable
					searchable
					{...form.getInputProps('currency')}
				/>
			</Collapse>

			<TextInput
				required
				label="Business ID"
				placeholder="eg. 4200000000000"
				type="number"
				min="0"
				step="1"
				{...form.getInputProps('businessId')}
			/>

			<TextInput
				label="Tax ID"
				placeholder="eg. 200000000000"
				type="number"
				min="0"
				step="1"
				{...form.getInputProps('taxId')}
			/>

			<Textarea
				required
				label="Address"
				placeholder="eg. 123 Street Name, 4000 City, Country"
				minRows={4}
				autosize
				{...form.getInputProps('address')}
			/>

			<Group position="apart" mt="md">
				<Group>
					{client && (
						<QuickButton icon={Trash} color="red" onClick={openDeleteModal}>
							Remove Client
						</QuickButton>
					)}
				</Group>
				<Group>
					<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
					<Button uppercase type="submit">{client ? 'Update' : 'Add'}</Button>
				</Group>
			</Group>
		</form>
	);
}
