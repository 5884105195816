import React from 'react';
import { Amount } from '../domain';
import { FinancialAccount } from '../api/types';
import { useSimpleMutation } from '../queryUtils';
import { useApi } from '../hooks/api.hooks';
import { useForm } from '@mantine/form';
import { Button, Group, Select, Text, TextInput, useMantineTheme } from '@mantine/core';

type Props = {
	target: FinancialAccount[];
	value?: Amount;
	operator: 'set' | 'deposit' | 'withdraw';
	submitLabel: string;
	onClose: () => void;
};

export default function SetBalance({
	target,
	value,
	operator,
	submitLabel,
	onClose,
}: Props) {
	const theme = useMantineTheme();
	const api = useApi();
	const setBalance = useSimpleMutation(api.finance.accounts.setBalance);

	const initialAccount = target.length === 1 ? target[0] : undefined;

	let initialAmount: string = '';

	if (value) {
		initialAmount = value.toDotNotation(20);
	} else if (operator === 'set' && initialAccount) {
		initialAmount = initialAccount.balance.toDotNotation(20);
	}

	const form = useForm({
		initialValues: {
			accountId: initialAccount?.id,
			amount: initialAmount,
		},
		validate: {
			accountId: (value) => value ? null : 'Account ID missing',
			amount: (value) => {
				let parseResult = Amount.parseResult(value, true);
				if (parseResult.ok) {
					return null;
				}
				return parseResult.errorMessage;
			},
		},
	});

	const accountInput = form.getInputProps('accountId');
	const amountInput = form.getInputProps('amount');

	return (
		<form
			onSubmit={form.onSubmit((values) => {
				if (!values.accountId) {
					return;
				}
				const account = target.find((item) => item.id === values.accountId);
				let amount = Amount.parse(values.amount, true);
				if (!account) {
					return;
				}
				switch (operator) {
					case 'set':
						break;
					case 'deposit':
						amount = account.balance.add(amount);
						break;
					case 'withdraw':
						amount = account.balance.sub(amount);
						break;
					default:
						return;
				}
				setBalance({
					accountId: values.accountId,
					balance: amount,
				});
				onClose();
			})}
		>
			{initialAccount ? (
				<Group direction="column" spacing={2}>
					<Group align="baseline">
						<Text
							sx={{ minWidth: 60 }}
							size="sm"
							color="dimmed"
						>
							Name
						</Text>
						<Text>{initialAccount.name}</Text>
					</Group>
					<Group align="baseline">
						<Text
							sx={{ minWidth: 60 }}
							size="sm"
							color="dimmed"
						>
							Authority
						</Text>
						<Text>{initialAccount.group}</Text>
					</Group>
					<Group align="baseline">
						<Text
							sx={{ minWidth: 60 }}
							size="sm"
							color="dimmed"
						>
							Currency
						</Text>
						<Text
							sx={{
								fontFamily: theme.other.currencyFont,
							}}
						>
							{initialAccount.currency}
						</Text>
					</Group>
				</Group>
			) : (
				<Select
					required
					label="Account"
					placeholder="Select target account..."
					data={target.map((account) => ({
						value: account.id,
						label: `${account.group} | ${account.name} | ${account.currency}`,
					}))}
					readOnly={target.length === 1}
					searchable
					value={accountInput.value}
					error={accountInput.error}
					onChange={(newValue) => {
						accountInput.onChange(newValue);
						if (value) {
							amountInput.onChange(value.toDotNotation(20));
						} else if (operator === 'set') {
							const account = target.find((item) => item.id === newValue);
							if (account) {
								amountInput.onChange(account.balance.toDotNotation(20));
							}
						}
					}}
				/>
			)}
			<TextInput
				required
				label={operator === 'set' ? 'Balance' : 'Amount'}
				placeholder="0.00"
				{...amountInput}
			/>
			<Group position="right" mt="md">
				<Button
					uppercase
					variant="subtle"
					color="gray"
					onClick={onClose}
				>
					Cancel
				</Button>
				<Button uppercase type="submit">{submitLabel}</Button>
			</Group>
		</form>
	);
}
