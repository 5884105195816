import { Amount } from '../domain';
import { Currency, Stock } from '../api/types';

export default function makeBamConversions(
	currencies: Currency[],
	stocks: { [key: string]: Stock },
): Map<string, Amount> {
	const conversions = new Map();

	currencies.forEach((currency) => {
		conversions.set(currency.code, currency.middle.div(currency.units));
	});

	Object.keys(stocks).forEach((symbol) => {
		const stock = stocks[symbol];
		const currency = conversions.get(stock.currency);
		if (!currency) {
			return;
		}
		conversions.set(symbol, stock.close.mul(currency));
	});

	return conversions;
}
