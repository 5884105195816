import { Button, Collapse, Group, Modal, Space, Switch, Textarea } from '@mantine/core';
import React from 'react';
import { formList, useForm } from '@mantine/form';
import { useClients } from '../../hooks/financialClients.hooks';
import { Amount } from '../../domain';
import AddServiceAmountForm from './AddServiceAmount.form';
import { useDisclosure } from '@mantine/hooks';
import { DatePicker } from '@mantine/dates';
import { ServiceListing } from './ServiceListing';
import ClientSelect from './ClientSelect';

type Props = {
	onClose: () => void;
	onSubmit: (props: {
		clientId: string;
		issuedAt: Date;
		comment: string;
		items: {
			serviceId: string;
			amount: Amount;
		}[];
	}) => void;
}

export default function CreateQuote({
	onClose,
	onSubmit,
}: Props): JSX.Element {
	const [addingService, setAddingService] = useDisclosure(false);
	const [showMore, setShowMore] = useDisclosure(false);
	const { data: clients = [] } = useClients();
	const form = useForm({
		initialValues: {
			clientId: '',
			issuedAt: new Date(),
			comment: '',
			items: formList<{ serviceId: string; amount: Amount }>([]),
		},
		validate: {
			clientId: (value) => clients.find((client) => client.id === value) ? null : 'Required',
		},
	});

	const client = clients.find((client) => client.id === form.values.clientId);

	return (
		<>
			<form onSubmit={form.onSubmit((values) => {
				const client = clients.find((client) => client.id === values.clientId);
				if (!client) {
					throw new Error('Invalid client');
				}
				const props = {
					clientId: values.clientId,
					issuedAt: values.issuedAt,
					comment: values.comment.trim(),
					items: values.items.map(({ serviceId, amount }) => {
						const service = client.services.find((service) => service.id === serviceId);
						if (!service) {
							throw new Error('Invalid service');
						}
						return { serviceId, amount };
					}),
				};
				onSubmit(props);
				onClose();
			})}>
				<ClientSelect
					required
					sx={{ maxWidth: '350px' }}
					{...form.getInputProps('clientId')}
				/>

				<Space h={8} />
				<Switch
					label="Show More"
					checked={showMore}
					onChange={setShowMore.toggle}
				/>
				<Space h={8} />

				<Collapse in={showMore}>
					<DatePicker
						required
						label="Issued At"
						placeholder="Pick date"
						clearable={false}
						sx={{
							maxWidth: '350px',
						}}
						{...form.getInputProps('issuedAt')}
					/>
					<Textarea
						label="Comment"
						placeholder="eg. Handled issue #742"
						minRows={2}
						autosize
						{...form.getInputProps('comment')}
					/>
				</Collapse>

				<ServiceListing
					client={client}
					items={form.values.items}
					remove={(indices) => form.removeListItem('items', indices)}
					add={setAddingService.open}
				/>

				<Group position="right" mt="md">
					<Button uppercase variant="subtle" color="gray" onClick={onClose}>Cancel</Button>
					<Button uppercase type="submit">
						Create
					</Button>
				</Group>
			</form>
			{addingService && (
				<Modal
					opened
					onClose={setAddingService.close}
					title="Add Quote Entry"
				>
					<AddServiceAmountForm
						clientServices={client?.services || []}
						onClose={setAddingService.close}
						onSubmit={(value) => form.addListItem('items', value)}
					/>
				</Modal>
			)}
		</>
	);
}
