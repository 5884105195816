import React from 'react';
import { useIntl } from 'react-intl';
import { Box, Center, Divider, Group, Space, Text, Title } from '@mantine/core';
import { DetailRow } from '../../components/DetailRow';
import MultilineText from '../../components/MultilineText';
import { ServiceTable } from './ServiceTable';
import NoPrint from '../../components/NoPrint';
import Letterhead from '../../layouts/other/Letterhead';
import { ServiceSum } from './ServiceSum';
import DetailLabel from '../../components/DetailLabel';
import { Amount, printExchangeRate } from '../../domain';
import PrintPaymentInfo from '../../layouts/payments/PrintPaymentInfo';
import OnlyPrint from '../../components/OnlyPrint';
import { FullClientInfo } from '../financialClients/FullClientInfo';
import DateAndBillNumber from '../../layouts/payments/DateAndBillNumber';
import { CompanyInfo, Invoice, PaymentQuote } from '../../api/types';

type PaymentDetailsProps = ({
	type: 'invoice';
	value: Invoice;
} | {
	type: 'quote';
	value: PaymentQuote;
}) & {
	companyInfo: CompanyInfo;
};

export default function PaymentDetails(props: PaymentDetailsProps): JSX.Element {
	const { type, value, companyInfo } = props;
	const intl = useIntl();

	let centralTitle: string;
	switch (type) {
		case 'invoice':
			centralTitle = intl.formatMessage({
				id: 'pwTiGh',
				defaultMessage: 'Invoice {id}',
				description: 'Title of invoice with the ID contained',
			}, { id: `${value.invoiceNumber}/${value.fiscalYear}` });
			break;
		case 'quote':
			centralTitle = intl.formatMessage({
				id: 'ePgSkO',
				defaultMessage: 'Quote',
				description: 'Title of quote',
			});
			break;
		default:
			centralTitle = '';
	}

	const vatNoteLabel = intl.formatMessage({
		id: 'OXqjgr',
		defaultMessage: 'VAT within Bosnia and Herzegovina not applicable according to section 15.2.4.a',
		description: 'Note describing VAT applicability for the specific invoice or quote',
	});
	const exchangeRateInUiLabel = intl.formatMessage({
		id: '0eHpR4',
		defaultMessage: 'Ex. Rate',
		description: 'Label for exchange rate info in invoice or quote UI',
	});
	const exchangeRateLabel = intl.formatMessage({
		id: 'U9IwJl',
		defaultMessage: 'Exchange rate: {rate}',
		description: 'Label for exchange rate info of invoice or quote',
	}, {
		rate: type === 'invoice' ? printExchangeRate(value.exchangeRate, intl.locale) : '-',
		from: value.clientInfo.currency,
		to: 'BAM',
	});
	const commentLabel = intl.formatMessage({
		id: 'W2XGXU',
		defaultMessage: 'Comment',
		description: 'Comment on invoice or quote',
	});

	let exchangeRate: Amount | undefined;
	switch (type) {
		case 'invoice':
			if (intl.locale === 'bs') {
				exchangeRate = value.exchangeRate;
			}
			break;
		case 'quote':
			if (value.clientInfo.currency === 'BAM') {
				exchangeRate = Amount.parse('1');
			}
	}

	return (
		<Group
			direction="column"
			spacing={0}
			grow
		>
			<Letterhead
				info={companyInfo}
				hideInWeb
			/>
			<Space h={5} />
			<Divider size="xs" />
			<Space h="sm" />
			<Group direction="row" position="apart" align="flex-end">
				<FullClientInfo
					clientInfo={value.clientInfo}
					forPrint
				/>
				<DateAndBillNumber {...props} />
			</Group>
			<Center>
				<Title order={1}>{centralTitle}</Title>
			</Center>
			<Space h="md" />
			<ServiceTable
				items={value.items}
				clientInfo={value.clientInfo}
			/>
			<Group direction="row" position="apart" align="flex-start">
				<Group direction="column" spacing="sm" sx={{ maxWidth: 400 }}>
					<Space />
					{value.clientInfo.international && (
						<OnlyPrint>
							<Text size="sm">
								{vatNoteLabel}
							</Text>
						</OnlyPrint>
					)}
					{type === 'invoice' && (
						<>
							<NoPrint>
								<Box>
									<DetailRow label={exchangeRateInUiLabel}>
										{printExchangeRate(value.exchangeRate, intl.locale)}
									</DetailRow>
								</Box>
							</NoPrint>
							{value.clientInfo.currency !== 'BAM' && intl.locale === 'bs' && (
								<OnlyPrint>
									<Text size="sm">{exchangeRateLabel}</Text>
								</OnlyPrint>
							)}
						</>
					)}
					{value.comment && (
						<Group direction="column" spacing={0}>
							<DetailLabel>{commentLabel}</DetailLabel>
							<Text size="sm">
								<MultilineText text={value.comment} />
							</Text>
						</Group>
					)}
				</Group>
				<ServiceSum
					items={value.items}
					clientInfo={value.clientInfo}
					exchangeRate={exchangeRate}
				/>
			</Group>
			<PrintPaymentInfo
				info={companyInfo}
				hideInWeb
			/>
			<NoPrint><Space h="sm" /></NoPrint>
		</Group>
	);
}
