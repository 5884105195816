import { FinancialAccount, FinancialAccountHistory } from '../api/types';
import { useApi } from './api.hooks';
import { useQuery, UseQueryResult } from 'react-query';

export function useAccounts(): UseQueryResult<FinancialAccount[], unknown> {
	const api = useApi();
	return useQuery('finance.accounts.getAll', api.finance.accounts.getAll);
}

export function useAccountHistory(accountId: string): UseQueryResult<FinancialAccountHistory, unknown> {
	const api = useApi();
	return useQuery(`finance.accounts.getHistory.${accountId}`, () => api.finance.accounts.getHistory({ accountId }));
}
