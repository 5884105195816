import React from 'react';
import { Avatar, Box, Group, Menu, Text, UnstyledButton, useMantineTheme } from '@mantine/core';
import { useAuth0 } from '@auth0/auth0-react';
import { ChevronRight, Logout } from 'tabler-icons-react';

export default function CurrentUserMenu() {
	const { logout, user } = useAuth0();
	const theme = useMantineTheme();

	if (!user) {
		return null;
	}

	return (
		<Menu
			sx={{
				width: '100%',
			}}
			control={
				<UnstyledButton
					sx={{
						display: 'block',
						width: '100%',
						padding: theme.spacing.xs,
						borderRadius: theme.spacing.sm,
						color: theme.black,

						'&:hover': {
							backgroundColor: theme.colors.gray[0],
						},
					}}
				>
					<Group noWrap sx={{ width: '100%' }}>
						<Avatar src={user.picture} radius="xl" />
						<Box sx={{ flex: 1, overflow: 'hidden' }}>
							<Text
								size="sm"
								weight={500}
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
								}}
							>
								{user.name}
							</Text>
							<Text
								color="dimmed"
								size="xs"
								sx={{
									overflow: 'hidden',
									whiteSpace: 'nowrap',
									textOverflow: 'ellipsis',
								}}
							>
								{user.email}
							</Text>
						</Box>
						<ChevronRight size={18} />
					</Group>
				</UnstyledButton>
			}
		>
			<Menu.Item
				icon={<Logout size={14} />}
				onClick={() => logout({ returnTo: window.location.origin })}>
				Sign out
			</Menu.Item>
		</Menu>
	)
		;
}
